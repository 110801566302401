import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingRessourcePage from '../../components/LoadingRes';
import { useAuthenticatedUser } from '../../contexts/auth';
import { isAnalyzed, IVideo } from '../../models/assets';
import Creatives from './Creatives';
import Metadata from './Metadata';
import { VideoDashboardProvider } from './context';

const VideoDashboard: React.FC = () => {
  const { id } = useParams();
  if (!id) throw new Error('No ID provided');
  const { api } = useAuthenticatedUser();
  const [video, setVideo] = useState<IVideo>();
  useEffect(() => {
    api.videos.one(id).then(setVideo);
  }, [api, id]);
  if (!video) return <LoadingRessourcePage resName='Analysis' />;
  if (!isAnalyzed(video))
    return <Typography color='error'>Asset is not analyzed</Typography>;
  return (
    <VideoDashboardProvider video={video}>
      <InnerVideoDashboard />
    </VideoDashboardProvider>
  );
};
export default VideoDashboard;

const InnerVideoDashboard: React.FC = () => {
  return (
    <Box>
      <Metadata />

      <Creatives />
    </Box>
  );
};
