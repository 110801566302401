import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import CreativeDataCards from '../../components/CreativeDataCards';
import PerformanceCard from '../../components/PerformanceCard';
import FiltersBar from '../FiltersBar';
import { FiltersContextProvider } from '../FiltersBar/context';
import GlobalGuidelinesScore from './GlobalGuidelinesScores';
import useDashboard, { DashboardProvider } from './context';
import ApiErrorComponent from '../../components/ApiErrorComponent';

const Dashboard: React.FC = () => {
  return (
    <FiltersContextProvider type='inflight' analyzed={true}>
      <DashboardProvider>
        <DashboardInner />
      </DashboardProvider>
    </FiltersContextProvider>
  );
};

const DashboardInner: React.FC = () => {
  let { selectedCd, results, isLoading, error } = useDashboard();

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <img width='64' src='/loading.gif' alt='Loading...' />
      </Box>
    );
  if (error) return <ApiErrorComponent error={error} />;
  if (!results) return null;
  const measures = results.Measures;
  return (
    <Box>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: '#fff',
        }}
      >
        <FiltersBar />
      </Box>
      <Stack height='100%' gap={2} sx={{ mt: 2 }}>
        <Stack direction='row' gap={2}>
          <PerformanceCard
            title='Impressions'
            iconName='Impressions'
            value={measures.Impressions.value}
            unit=''
            data={measures.Impressions.history}
          />
          <PerformanceCard
            title='Spend'
            iconName='Spend'
            value={measures.Spend.value}
            unit='$'
            data={measures.Spend.history}
          />
          <GlobalGuidelinesScore />
        </Stack>

        <Box>
          <Box py={2}>
            <Grid container spacing={2}>
              <CreativeDataCards
                selectedCd={selectedCd}
                creativeData={results.CreativeData}
              />
            </Grid>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Dashboard;
