import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { ApiError, parseApiError } from '../services/api';

const ApiErrorComponent: React.FC<{ error: ApiError | undefined | null }> = ({
  error,
}) => {
  if (!error) return null;
  if ((error.status ?? 500) >= 500)
    return (
      <Typography color='error'>
        Unexpected Server Error, please report to team
      </Typography>
    );
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography color='error' variant='body2'>
          {parseApiError(error)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color='error' component='pre' variant='caption'>
          {JSON.stringify(error.response?.data.details, null, 2)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
export default ApiErrorComponent;
