import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * A hook to get and set a URL search param. Stabler than `useSearchParamState`, but does not support batch updates.
 * @param key The search param key
 * @param defaultValue The default value if the search param is not found
 * @returns A tuple containing the search param value and a SetStateAction to set the search param value
 */
export default function useSearchParam<T extends string | undefined>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T | undefined>>];
export default function useSearchParam<T extends string | undefined>(
  key: string,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>];
export default function useSearchParam<T extends string | undefined>(
  key: string,
  defaultValue?: T,
): [T | string | null, Dispatch<SetStateAction<T | string | null>>] {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  return [
    (urlSearchParams.get(key) as T | undefined) ?? defaultValue ?? null,
    (value: SetStateAction<T | string | null>) => {
      setUrlSearchParams(prev => {
        const searchParams = new URLSearchParams(prev);
        const newValue =
          typeof value === 'function'
            ? value(searchParams.get(key) as T | null)
            : value;
        if (!newValue) searchParams.delete(key);
        else searchParams.set(key, newValue);
        return searchParams;
      });
    },
  ];
}
