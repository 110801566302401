import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../contexts/auth';
import { Ambassador, AmbassadorType } from '../models/Ambassador';
import { ApiError } from '../services/api';
import ApiErrorComponent from './ApiErrorComponent';

interface AmbassadorSearchProps {
  base_ambassador?: AmbassadorType | null;
  onApply: (a: Ambassador) => Promise<void>;
  onCreateNew: () => void;
  loading: Boolean;
  apiError: ApiError | undefined;
}

const AmbassadorSearch: React.FC<AmbassadorSearchProps> = ({
  base_ambassador,
  onApply,
  onCreateNew,
  loading,
  apiError,
}) => {
  const { api } = useAuthenticatedUser();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Ambassador[]>([]);
  const [selectedAmbassador, setSelectedAmbassador] =
    useState<Ambassador | null>(null);
  //TODO HANDLE base_ambassador
  useEffect(() => {
    const searchCelebrities = async () => {
      if (searchQuery) {
        try {
          const results = await api.ambassadors.search(searchQuery);
          setSearchResults(results);
        } catch (error) {
          console.error('Failed to search celebrities:', error);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    };

    const debounceTimeout = setTimeout(searchCelebrities, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchQuery, api.ambassadors]);

  return (
    <Box sx={{ p: 2 }}>
      <TextField
        fullWidth
        label='Search person by name'
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
      />
      {searchResults.length > 0 ? (
        <>
          <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
            {searchResults.map(ambassador => (
              <Box
                key={ambassador.name}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 2,
                  cursor: 'pointer',
                  bgcolor:
                    selectedAmbassador?.name === ambassador.name
                      ? 'action.selected'
                      : 'transparent',
                  '&:hover': { bgcolor: 'action.hover' },
                }}
                onClick={() => setSelectedAmbassador(ambassador)}
              >
                <Box
                  component='img'
                  src={ambassador.image_url}
                  sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
                <Box>
                  <Typography variant='subtitle1'>{ambassador.name}</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    {ambassador.profession || 'unknown'} •{' '}
                    {ambassador.nationality || 'unknown'}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <ApiErrorComponent error={apiError} />

          {selectedAmbassador && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant='contained'
                  onClick={() => onApply(selectedAmbassador)}
                  color='primary'
                >
                  Apply
                </Button>
              )}
            </Box>
          )}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', my: 3 }}>
          <Typography color='text.secondary'>No matches found</Typography>
          <Button variant='contained' onClick={onCreateNew} sx={{ mt: 2 }}>
            Add as New Ambassador
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AmbassadorSearch;
