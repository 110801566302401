import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AmbassadorEdit from '../../components/AmbassadorEdit';
import AmbassadorMerge from '../../components/AmbassadorMerge';
import AmbassadorSearch from '../../components/AmbassadorSearch';
import { useAuthenticatedUser } from '../../contexts/auth';
import {
  AmbassadorList,
  ambassadorToCelebrity,
  AmbassadorType,
  mapCelebrityToAmbassador,
  UnknownAmbassador,
  UnknownIdentifier,
  UnknownPayload,
} from '../../models/Ambassador';
import { ApiError } from '../../services/api';

interface AmbassadorModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (a: AmbassadorType) => void;
  ambassadors: AmbassadorList;
  unknown?: boolean;
  merge?: boolean;
}

const extractUnknownIdentifier = (a: AmbassadorType): UnknownIdentifier => {
  const data = a as UnknownAmbassador;
  return {
    asset_id: data.asset_id,
    source: data.source,
    unknown_id: data.unknown_id,
    blob_storage_path: data.image_url,
  };
};

const AmbassadorModal = ({
  open,
  onClose,
  onSave,
  ambassadors,
  unknown = false,
  merge = false,
}: AmbassadorModalProps) => {
  const [mode, setMode] = useState<
    'edit' | 'create' | 'search' | 'createFromUnknown' | 'merge'
  >('search');
  const [ambassadorIds, setAmbassadorIds] = useState<UnknownIdentifier[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApiError>();
  const { api } = useAuthenticatedUser();

  useEffect(() => {
    if (ambassadors.length > 0) {
      if (unknown) {
        setMode('search');
        setAmbassadorIds(ambassadors.map(extractUnknownIdentifier));
      } else if (merge) setMode('merge');
      else if (ambassadors[0].celebrity_id === 0) setMode('create');
      else setMode('edit');
    }
  }, [ambassadors, unknown, merge]);

  const handleCreateNew = () => {
    setMode('createFromUnknown');
    setAmbassadorIds(ambassadors.map(extractUnknownIdentifier));
  };

  const handleClose = () => {
    setApiError(undefined);
    onClose();
  };

  const handleApplyMapping = async (a: AmbassadorType) => {
    setLoading(true);
    try {
      const ambassador_data = a as UnknownAmbassador;
      const payload: UnknownPayload = {
        asset_id: ambassadorIds[0].asset_id,
        source: ambassadorIds[0].source,
        unknown_id: ambassadorIds[0].unknown_id,
        unknown_data: ambassadorToCelebrity(a),
        blob_storage_path: ambassador_data.image_url,
        additional_faces: [],
      };
      await api.ambassadors.mapUnknownToExisting(ambassadorIds, payload);
      onSave(a);
    } catch (error) {
      console.error('Failed to map ambassador:', error);
      setApiError(error as ApiError);
      //setError(true);
    }
    setLoading(false);
  };

  const editAmbassador = async (a: AmbassadorType, files: File[]) => {
    setLoading(true);
    try {
      const celebrity = ambassadorToCelebrity(a);
      await api.ambassadors.update(celebrity.celebrity_id, celebrity, files);
      onSave(a);
    } catch (error) {
      console.error('Failed to update Ambassador Data', error);
      setApiError(error as ApiError);
      // setError(true);
      // setErrorMessage('An error occured. Try again later.');
    }
    setLoading(false);
  };

  const mergeAmbassadors = async (
    mergedAmbassador: AmbassadorType,
    otherId: number,
  ) => {
    setLoading(true);
    try {
      const celebrity = ambassadorToCelebrity(mergedAmbassador);
      const updatedAmbassador = await api.ambassadors.merge(celebrity, otherId);
      onSave(mapCelebrityToAmbassador(updatedAmbassador));
    } catch (error) {
      setApiError(error as ApiError);
      // setError(true);
      // setErrorMessage(
      //   'Possible loss of critical data detected, stopping merging process',
      // );
    }
    setLoading(false);
  };

  const createAmbassador = async (a: AmbassadorType, files: File[]) => {
    setLoading(true);
    try {
      if (mode === 'createFromUnknown') {
        const ambassador_data = a as UnknownAmbassador;
        const editedIds = extractUnknownIdentifier(ambassador_data);
        const other_unknown = ambassadorIds.filter(
          o => JSON.stringify(o) !== JSON.stringify(editedIds),
        );
        const payload: UnknownPayload = {
          asset_id: ambassador_data.asset_id,
          source: ambassador_data.source,
          unknown_id: ambassador_data.unknown_id,
          unknown_data: ambassadorToCelebrity(a),
          blob_storage_path: ambassador_data.image_url,
          additional_faces: files,
        };
        await api.ambassadors.createFromUnknown(other_unknown, payload);
      } else {
        const celebrity = ambassadorToCelebrity(a);
        await api.ambassadors.create(celebrity, files);
      }
      onSave(a);
    } catch (error) {
      setApiError(error as ApiError);
      //setError(true);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 3,
        }}
      >
        {mode === 'search' && (
          <Typography variant='h6'>Search Existing Ambassador</Typography>
        )}{' '}
        {mode === 'edit' && (
          <Typography variant='h6'>Edit Ambassador</Typography>
        )}
        {mode === 'create' ||
          (mode === 'createFromUnknown' && (
            <Typography variant='h6'>New Ambassador</Typography>
          ))}
        {mode === 'merge' && (
          <Typography variant='h6'>Merge Ambassadors</Typography>
        )}
        <IconButton onClick={handleClose} size='small'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {mode === 'search' && (
          <AmbassadorSearch
            onApply={handleApplyMapping}
            onCreateNew={handleCreateNew}
            loading={loading}
            apiError={apiError}
          />
        )}
        {(mode === 'edit' ||
          mode === 'createFromUnknown' ||
          mode === 'create') && (
          <AmbassadorEdit
            mode={mode}
            ambassadors={ambassadors}
            onApply={mode === 'edit' ? editAmbassador : createAmbassador}
            onCancel={handleClose}
            loading={loading}
            apiError={apiError}
          />
        )}
        {mode === 'merge' && ambassadors.length >= 2 && (
          <AmbassadorMerge
            ambassador1={ambassadors[0]}
            ambassador2={ambassadors[1]}
            loading={loading}
            onMerge={mergeAmbassadors}
            onCancel={handleClose}
            apiError={apiError}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AmbassadorModal;
