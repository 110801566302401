import { Indexation, Model, PerfKey, Platform, Source } from '.';
import { ICreativeData } from './creativeData';
import { IGuidelineScore } from './guidelines';
import { User } from './users';

export type AssetType = 'video' | 'image';
export type AssetLifecycle = 'inflight' | 'preflight';

export interface IAssetBase extends Model {
  metadata: {
    source: Source;
    assetId: string;
    name: string;
    platform: Platform;
    brand: string;
    country: string;
    campaign: string;
    title?: string;
    description?: string;
  };
  assetType: AssetType;
  lifecycle: AssetLifecycle;
  creativeData?: ICreativeData;
}

interface IVideoBase extends IAssetBase {
  metadata: IAssetBase['metadata'] & {
    // not used for now
    // videoPath?: string;
    // thumbnailPath?: string;
    videoUrl?: string;
    thumbnailUrl?: string;
  };
  guidelinesScores?: {
    brand: IGuidelineScore;
    platform: IGuidelineScore;
  };
  assetType: 'video';
}

interface IImageBase extends IAssetBase {
  metadata: IAssetBase['metadata'] & {
    // not used for now
    // path?: string;
    url?: string;
  };
  assetType: 'image';
}

interface IInflightBase extends IAssetBase {
  metadata: IAssetBase['metadata'] & {
    publishedAt: Date;
    externalUrl: string;
  };
  measures: IVideoMetric[];
  lifecycle: 'inflight';
}

interface IPreflightBase extends IAssetBase {
  metadata: IAssetBase['metadata'] & {
    objectives: PerfKey[];
    authorId: number;
  };
  lifecycle: 'preflight';
}

export type IInflightVideo = IInflightBase & IVideoBase;
export type IInflightImage = IInflightBase & IImageBase;
export type IPreflightVideo = IPreflightBase & IVideoBase;
export type IPreflightImage = IPreflightBase & IImageBase;

export type IInflight = IInflightVideo | IInflightImage;
export type IPreflight = IPreflightVideo | IPreflightImage;
export type IVideo = IInflightVideo | IPreflightVideo;
export type IImage = IInflightImage | IPreflightImage;

export type IAsset =
  | IInflightVideo
  | IInflightImage
  | IPreflightVideo
  | IPreflightImage;

export type WithIndexation<T extends IAssetBase = IAsset> = T & {
  indexation: Indexation;
};
export type WithAuthor<T extends IPreflightBase = IPreflightBase> = T & {
  author: User;
};
export type IAnalyzed<T extends IAsset> = Required<T> & {
  metadata: Required<T['metadata']>;
};

export function isAnalyzed<T extends IAsset>(asset: T): asset is IAnalyzed<T> {
  return !!asset.creativeData;
}
export function isInflight<T extends IAsset>(asset: T): asset is T & IInflight {
  return asset.lifecycle === 'inflight';
}
export function isPreflight<T extends IAsset>(
  asset: T,
): asset is T & IPreflight {
  return asset.lifecycle === 'preflight';
}
export function isVideo<T extends IAsset>(asset: T): asset is T & IVideo {
  return asset.assetType === 'video';
}
export function isImage<T extends IAsset>(asset: T): asset is T & IImage {
  return asset.assetType === 'image';
}

export interface IVideoMetric {
  date: Date;
  spendUsd: number;
  spendEur: number;
  views: number;
  impressions: number;
  CTR?: number;
  ConversionRate?: number;
  RetentionRate?: number;
  WatchTime?: number;
}

export type CreatePreflightVideo = Omit<
  IPreflightVideo,
  '_id' | 'lifecycle' | 'metadata'
> & {
  metadata: Omit<IPreflight['metadata'], 'assetId' | 'authorId'>;
};
