import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect, useMemo, useState } from 'react';
import LoadingRessourcePage from '../../components/LoadingRes';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IPreflightVideo } from '../../models/assets';
import IInsight, {
  IMetricEvaluation,
  IVideoEvaluation,
} from '../../models/insights';
import { ImpactFormat, PercentageFormat } from '../../utils/formatters';
import { InsightCard } from '../Insights/Cards/Card';
import InsightFocus from '../Insights/Cards/InsightFocus';
import { InsightCardsProvider } from '../Insights/Cards/context';

const PreflightEvaluation: React.FC<{ video: IPreflightVideo }> = ({
  video,
}) => {
  const { api } = useAuthenticatedUser();
  const [result, setResult] = useState<IVideoEvaluation>();
  const [activeTab, setActiveTab] = useState<number>(0);
  useEffect(() => {
    api.insights.evaluate(video._id, video.metadata.objectives).then(setResult);
  }, [api, video]);
  if (!result) return <LoadingRessourcePage resName='Evaluation' />;
  return (
    <Box
      sx={{
        position: 'relative',
        p: 2,
        borderRadius: '20px',
        background: theme => theme.palette.gradient.vertical,
      }}
    >
      <Typography variant='h5' align='center'>
        Preflight Evaluation
      </Typography>
      <Box position='absolute' top={0} right={0} p={2}>
        <InformationTooltip />
      </Box>
      <Tabs
        value={activeTab}
        onChange={(_, newValue: number) => setActiveTab(newValue)}
        centered
        sx={{ position: 'relative' }}
      >
        {video.metadata.objectives.map(o => (
          <Tab key={o} label={o} />
        ))}
      </Tabs>
      {video.metadata.objectives.map((o, i) => (
        <MetricEvaluationTabPanel
          key={o}
          index={i}
          value={activeTab}
          evaluation={result.metrics[o]}
        />
      ))}
    </Box>
  );
};
export default PreflightEvaluation;

function MetricEvaluationTabPanel(props: {
  index: number;
  value: number;
  evaluation?: IMetricEvaluation;
}) {
  const { value, index, evaluation } = props;
  return (
    <div role='tabpanel' hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {evaluation ? (
            <MetricEvaluation evaluation={evaluation} />
          ) : (
            <Typography variant='body1'>
              No insights found for this metric
            </Typography>
          )}
        </Box>
      )}
    </div>
  );
}

const MetricEvaluation: React.FC<{ evaluation: IMetricEvaluation }> = ({
  evaluation,
}) => {
  const { complianceScore, impact } = evaluation;
  const [collapseInsights, setCollapseInsights] = useState(false);
  const [insightsClones, setInsightsClones] = useState(
    evaluation.matchingInsights.concat(evaluation.nonMatchingInsights),
  );
  const matchingInsights = useMemo(
    () =>
      insightsClones.filter(i =>
        evaluation.matchingInsights.some(mi => mi._id === i._id),
      ),
    [evaluation.matchingInsights, insightsClones],
  );
  const nonMatchingInsights = useMemo(
    () =>
      insightsClones.filter(i =>
        evaluation.nonMatchingInsights.some(mi => mi._id === i._id),
      ),
    [evaluation.nonMatchingInsights, insightsClones],
  );
  return (
    <InsightCardsProvider
      insights={insightsClones}
      setInsights={setInsightsClones}
    >
      <Grid2 container mb={2}>
        <Grid2 xs={6}>
          <Typography variant='body1'>
            Compliance: {PercentageFormat.format(complianceScore)}
          </Typography>
        </Grid2>
        <Grid2 xs={6}>
          <Typography variant='body1'>
            Predicted impact: {ImpactFormat.format(impact)}
          </Typography>
        </Grid2>
        <Grid2 xs={12}></Grid2>
      </Grid2>
      <InsightFocus />
      <ListItemButton onClick={() => setCollapseInsights(prev => !prev)}>
        <ListItemText primary='Insights' />
        {collapseInsights ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse in={!collapseInsights} timeout='auto' unmountOnExit>
        <Grid2 container>
          <Grid2 xs={6}>
            <InsightsColumn insights={matchingInsights} title='Matching' />
          </Grid2>
          <Grid2 xs={6}>
            <InsightsColumn
              insights={nonMatchingInsights}
              title='Non Matching'
            />
          </Grid2>
        </Grid2>
      </Collapse>
    </InsightCardsProvider>
  );
};

interface InsightsColumnProps {
  title: string;
  insights: IInsight[];
}
const InsightsColumn: React.FC<InsightsColumnProps> = props => {
  const { title, insights } = props;
  return (
    <Box>
      <Typography variant='subtitle1' align='center'>
        {title}
      </Typography>
      <Stack spacing={1} p={1}>
        {insights.map(insight => (
          <InsightCard key={insight._id} insight={insight} />
        ))}
      </Stack>
    </Box>
  );
};

const InformationTooltip: React.FC = () => {
  return (
    <Tooltip
      placement='left-start'
      title={
        <Box>
          <Typography variant='body1'>
            This is an evaluation of your preflight video, on the objectives you
            selected.
          </Typography>
          <Typography variant='body1'>
            It is computed based on insights, either bookmarked by you or
            defined by your brand
          </Typography>
        </Box>
      }
    >
      <InfoOutlined />
    </Tooltip>
  );
};
