// PresenceDialog.tsx
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../contexts/auth';
import useVideoDashboard from '../features/VideoDashboard/context';
import {
  Ambassador,
  ambassadorToCelebrity,
  ambassadorToPeople,
  AmbassadorType,
  Celebrity,
  combinePeopleAndCelebrityToAmbassador,
  mapPeopleToAmbassador,
  People,
  UnknownAmbassador,
  UnknownIdentifier,
  UnknownPayload,
} from '../models/Ambassador';
import { IAnalyzed, IVideo } from '../models/assets';
import AmbassadorEdit from './AmbassadorEdit';
import AmbassadorSearch from './AmbassadorSearch';

interface PresenceDialogProps {
  name?: string;
  onClose: () => void;
}

const PresenceDialog: React.FC<PresenceDialogProps> = ({ name, onClose }) => {
  const { video, setVideo } = useVideoDashboard();
  const { creativeData } = video;
  const [editMode, setEditMode] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<AmbassadorType | null>(
    null,
  );
  const [editedPerson, setEditedPerson] = useState<AmbassadorType | null>(null);
  const [showPersonSearch, setShowPersonSearch] = useState(false);
  const [updateScope, setUpdateScope] = useState<'single' | 'all'>('single');
  const [loading, setLoading] = useState<Boolean>(false);
  const [error, setError] = useState<Boolean>(false);
  const { api } = useAuthenticatedUser();

  const extractUnknownIdentifier = (
    video: IAnalyzed<IVideo>,
    p: People,
  ): UnknownIdentifier => {
    return {
      asset_id: video.metadata.assetId,
      source: video.metadata.source,
      unknown_id: p.id,
      blob_storage_path: p.image,
    };
  };

  useEffect(() => {
    const getImageUrl = async (ambassador: Ambassador) => {
      const urlMatcher = /^https?:\/\/[^\s/$.?#].[^\s]*$/;
      const signedUrl = urlMatcher.test(ambassador.image_url)
        ? ambassador.image_url
        : await api.url.getSignedUrl(ambassador.image_url);
      return signedUrl;
    };
    const getPersonData = async () => {
      if (name && creativeData.people) {
        const people = creativeData.people.find((p: People) => p.name === name);
        if (people) {
          if (!name.startsWith('Unknown #') && people.celebrityId) {
            var celebrity: Celebrity = await api.ambassadors.getBycelebrityId(
              people.celebrityId,
            );
            let ambassador: Ambassador = combinePeopleAndCelebrityToAmbassador(
              people,
              celebrity,
            );
            if (ambassador.image_url)
              ambassador.image_url = await getImageUrl(ambassador);
            setEditedPerson(ambassador);
            setSelectedPerson(ambassador);
          } else {
            const unknownIdentifier = extractUnknownIdentifier(video, people);
            let ambassador: AmbassadorType = {
              ...mapPeopleToAmbassador(people),
              ...unknownIdentifier,
            };
            if (ambassador.image_url)
              ambassador.image_url = await getImageUrl(ambassador);
            setEditedPerson(ambassador);
            setSelectedPerson(ambassador);
          }
        }
      }
    };
    getPersonData();
  }, [name, creativeData, updateScope, api.ambassadors, video, api.url]);

  const markAsUnknown = useCallback(async () => {
    const updatedPeople = [...video.creativeData.people];

    for (const p of updatedPeople) {
      if (selectedPerson && p.celebrityId === selectedPerson.celebrity_id) {
        p.celebrityId = 0;
        p.name = `Unknown #${p.id}`;
        p.knownPersonId = '';
        p.profession = '';
        p.nationality = '';
        p.isVerified = false;
        p.confidence = 0;
        p.celebrityId = 0;
        p.image = `images/${video.metadata.source}/${video.metadata.assetId}/unknown/${p.thumbnailId}.jpg`;

        try {
          const result = await api.videos.updatePerson(
            video._id,
            selectedPerson.name,
            p,
          );

          setVideo(prev => {
            return {
              ...prev,
              creativeData: {
                ...prev.creativeData,
                people: result.creativeData.people,
              },
            };
          });
          onClose();
        } catch (error) {
          console.error('Error updating person:', error);
        }

        break; // Exit loop after updating the first match
      }
    }
  }, [video, selectedPerson, api, setVideo, onClose]);

  if (!selectedPerson || !editedPerson) return null;

  const changeAmbassador = async (a: AmbassadorType): Promise<void> => {
    setLoading(true);
    try {
      if (name) {
        // Encode the person name if it contains "Unknown #" pattern
        const encodeName = (name: string) => {
          return encodeURIComponent(name);
        };
        const oldName = encodeName(name);
        if (name.startsWith('Unknown #')) {
          const ambassador_data = a as Ambassador;
          const unknown = selectedPerson as UnknownAmbassador;
          const payload: UnknownPayload = {
            asset_id: unknown.asset_id,
            source: unknown.source,
            unknown_id: unknown.unknown_id,
            unknown_data: ambassadorToCelebrity(a),
            blob_storage_path: ambassador_data.image_url,
            additional_faces: [],
          };
          const videoData = await api.ambassadors.mapUnknownToExisting(
            [
              {
                asset_id: payload.asset_id,
                source: payload.source,
                unknown_id: payload.unknown_id,
                blob_storage_path: payload.blob_storage_path,
              },
            ],
            payload,
          );
          setVideo(prev => {
            return {
              ...prev,
              creativeData: {
                ...prev.creativeData,
                people: videoData.creativeData.people,
              },
            };
          });
        } else {
          if (updateScope === 'single') {
            const people: People = ambassadorToPeople(a);
            await api.videos.updatePerson(video._id, oldName, people);
          } else {
            const celebrity: Celebrity = ambassadorToCelebrity(a);
            await api.ambassadors.update(celebrity.celebrity_id, celebrity, []);
          }
        }
        onClose();
      } else
        console.error('Dialog opened without name props should not happen');
    } catch (error) {
      console.error('Error while changing ambassador', error);
      setError(true);
    }
    setLoading(false);
  };

  const createFromUnknown = async (
    a: AmbassadorType,
    files?: File[],
  ): Promise<void> => {
    setLoading(true);
    try {
      const ambassador_data = a as UnknownAmbassador;
      const payload: UnknownPayload = {
        asset_id: ambassador_data.asset_id,
        source: ambassador_data.source,
        unknown_id: ambassador_data.unknown_id,
        unknown_data: ambassadorToCelebrity(a),
        blob_storage_path: ambassador_data.image_url,
        additional_faces: files ? files : [],
      };
      const videoData = await api.ambassadors.createFromUnknown([], payload);
      setVideo(prev => {
        return {
          ...prev,
          creativeData: {
            ...prev.creativeData,
            people: videoData.creativeData.people,
          },
        };
      });
      onClose();
    } catch (error) {
      console.error('Failed to map ambassador:', error);
      setError(true);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setEditedPerson(selectedPerson);
    setEditMode(false);
    setShowPersonSearch(false);
    setUpdateScope('single');
  };

  return (
    <Dialog open={!!selectedPerson} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!editMode && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant='h6'>{selectedPerson.name}</Typography>
            <Button
              variant='outlined'
              size='small'
              onClick={() => setShowPersonSearch(true)}
            >
              Replace Person
            </Button>
            {!name?.startsWith('Unknown #') && (
              <Button
                variant='outlined'
                size='small'
                onClick={() => markAsUnknown()}
              >
                Mark As Unknown
              </Button>
            )}
          </Box>
        )}
        <IconButton onClick={onClose} size='small' sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showPersonSearch ? (
          <>
            <AmbassadorSearch
              onApply={changeAmbassador}
              onCreateNew={() => {
                setEditMode(!editMode);
                setShowPersonSearch(!showPersonSearch);
              }}
              loading={loading}
              apiError={undefined}
            />
            {error && (
              <Typography sx={{ color: 'red' }}>
                An error occured during the process.
              </Typography>
            )}
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 4, p: 2 }}>
            {!editMode && (
              <Box sx={{ flex: '0 0 200px' }}>
                <img
                  src={
                    editMode ? editedPerson.image_url : selectedPerson.image_url
                  }
                  alt={selectedPerson.name}
                  style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginBottom: '8px',
                  }}
                />
              </Box>
            )}
            <Box
              sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              {editMode ? (
                <>
                  {!name?.startsWith('Unknown #') && (
                    <Box
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                      }}
                    >
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>Update Scope</FormLabel>
                        <RadioGroup
                          row
                          value={updateScope}
                          onChange={e =>
                            setUpdateScope(e.target.value as 'single' | 'all')
                          }
                        >
                          <FormControlLabel
                            value='single'
                            control={<Radio />}
                            label='Update for this video only'
                          />
                          <FormControlLabel
                            value='all'
                            control={<Radio />}
                            label='Update ambassador details'
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  )}
                  <AmbassadorEdit
                    mode={
                      name?.startsWith('Unknown #')
                        ? 'createFromUnknown'
                        : 'edit'
                    }
                    ambassadors={[editedPerson]}
                    onApply={
                      name?.startsWith('Unknown #')
                        ? createFromUnknown
                        : changeAmbassador
                    }
                    onCancel={() => setEditMode(!editMode)}
                    loading={loading}
                    apiError={undefined}
                  />
                  {error && (
                    <Typography sx={{ color: 'red' }}>
                      An error occured during the process.
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography variant='body1'>
                    <strong>Age:</strong> {selectedPerson.age}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Gender:</strong> {selectedPerson.gender}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Skin Tone:</strong> {selectedPerson.skin_tone}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Nationality:</strong> {selectedPerson.nationality}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Profession:</strong> {selectedPerson.profession}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Total Appearances:</strong>{' '}
                    {selectedPerson.appearances?.length || 0}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 2,
            p: 2,
          }}
        >
          {showPersonSearch && (
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {!editMode && !showPersonSearch && (
            <Button variant='contained' onClick={() => setEditMode(!editMode)}>
              Edit
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PresenceDialog;
