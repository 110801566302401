export const platforms = [
  'YouTube',
  'Facebook',
  'Instagram',
  'Twitter',
  'TikTok',
  'Unknown',
] as const;
export type Platform = (typeof platforms)[number];

export const sources = ['internal', 'opera', 'bic'] as const;
export type Source = (typeof sources)[number];

export interface Model<T = string> {
  _id: T;
}

interface MeasureData {
  value: number;
  history: number[];
}

export interface IVideoSummary {
  VideosCount: number;
  VideosDuration: number;
  Measures: {
    Impressions: MeasureData;
    WatchTime: MeasureData;
    Spend: MeasureData;
    CTR: MeasureData;
    ConversionRate: MeasureData;
    Views: MeasureData;
  };
  CreativeData: any;
}

export const perfKeys = [
  'views',
  'impressions',
  'CTR',
  'ConversionRate',
  'RetentionRate',
  'WatchTime',
] as const;
export type PerfKey = (typeof perfKeys)[number];
export function isPerfKey(key: string): key is PerfKey {
  return perfKeys.includes(key as PerfKey);
}

export interface Indexation {
  ci_id: string;
  source: Source;
  asset_id: string;
  brand: string;
  download_url?: string;
  download_status: IndexationStatus;
  video_blob_path?: string;
  avi_status: IndexationStatus;
  avi_id?: string;
  postprocess_status: IndexationStatus;
  version?: string;
  created_at: Date;
  completed_at?: Date;
}
// !! Order is important here, it will be used to sort the indexations and get the global status
export const indexationStatuses = [
  'completed',
  'pending',
  'processing',
  'failed',
] as const;
export type IndexationStatus = (typeof indexationStatuses)[number];

export function compareIndexation(a: Indexation, b: Indexation) {
  return comparateStatus(a.postprocess_status, b.postprocess_status);
}
function comparateStatus(a: IndexationStatus, b: IndexationStatus) {
  return indexationStatuses.indexOf(a) - indexationStatuses.indexOf(b);
}

export interface Brand {
  BrandID: number;
  BrandName: string;
}
