import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { IAnalyzed, IVideo } from '../../models/assets';

const VideoDashboardContext = createContext<DashboardContextType | null>(null);

interface DashboardContextType {
  video: IAnalyzed<IVideo>;
  setVideo: React.Dispatch<React.SetStateAction<IAnalyzed<IVideo>>>;
  seekTime: number;
  setSeekTime: (time: number) => void;
}

type DashboardProviderProps = PropsWithChildren<{
  video: IAnalyzed<IVideo>;
}>;
export const VideoDashboardProvider: React.FC<
  DashboardProviderProps
> = props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { children, video } = props;
  const [current_video, setVideo] = useState<IAnalyzed<IVideo>>(video);
  const [seekTime, _setSeekTime] = useState<number>(0);

  const setSeekTime = useCallback(
    (time: number) => {
      setSearchParams({ seekTime: time.toString() });
    },
    [setSearchParams],
  );
  useEffect(() => {
    const seekTime = searchParams.get('seekTime');
    if (seekTime) _setSeekTime(Number(seekTime));
  }, [searchParams]);
  const value = {
    video: current_video,
    setVideo,
    seekTime,
    setSeekTime,
  };
  return (
    <VideoDashboardContext.Provider value={value}>
      {children}
    </VideoDashboardContext.Provider>
  );
};

export default function useVideoDashboard() {
  const ctx = useContext(VideoDashboardContext);
  if (!ctx) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return ctx;
}
