import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  parsePresetDateRange,
  PresetDateRange,
} from '../../components/PresetDateRangePicker';
import { useAuthenticatedUser } from '../../contexts/auth';
import useSearchParamState from '../../hooks/useSeachParamState';
import { Platform } from '../../models';
import { AssetLifecycle } from '../../models/assets';

const FiltersContext = createContext<FiltersContextType | null>(null);

type FiltersContextProviderProps = PropsWithChildren<{
  analyzed?: boolean;
  type?: AssetLifecycle;
  noDateRange?: boolean;
}>;
export const FiltersContextProvider: FC<
  FiltersContextProviderProps
> = props => {
  const { children, analyzed, type, noDateRange = false } = props;
  const { api } = useAuthenticatedUser();
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>({
    platforms: [],
    brands: [],
    countries: [],
    campaigns: [],
  });
  const [platform, setPlatform] = useSearchParamState<Platform>('platform');
  const [brand, setBrand] = useSearchParamState<string>('brand');
  const [country, setCountry] = useSearchParamState<string>('country');
  const [campaign, setCampaign] = useSearchParamState<string>('campaign');
  const [dateRange, setDateRange] =
    useSearchParamState<PresetDateRange>('dateRange');

  const filters = useMemo(
    () => ({
      platform,
      brand,
      country,
      campaign,
      analyzed,
      type,
      ...parsePresetDateRange(dateRange),
    }),
    [platform, brand, country, campaign, analyzed, type, dateRange],
  );

  const setAvailable =
    <K extends keyof AvailableFilters>(key: K) =>
    (value: AvailableFilters[K]) =>
      setAvailableFilters(af => {
        if (af[key] === value) return af;
        return { ...af, [key]: value };
      });

  useEffect(() => {
    const filter = getFilterForAvailable(filters, 'platform', noDateRange);
    api.assets.platforms(filter).then(setAvailable('platforms'));
  }, [api, filters, noDateRange]);

  useEffect(() => {
    const filter = getFilterForAvailable(filters, 'brand', noDateRange);
    api.assets.brands(filter).then(setAvailable('brands'));
  }, [api, filters, noDateRange]);

  useEffect(() => {
    const filter = getFilterForAvailable(filters, 'country', noDateRange);
    api.assets.countries(filter).then(setAvailable('countries'));
  }, [api, filters, noDateRange]);

  useEffect(() => {
    const filter = getFilterForAvailable(filters, 'campaign', noDateRange);
    api.assets.campaigns(filter).then(setAvailable('campaigns'));
  }, [api, filters, noDateRange]);

  useEffect(() => {
    if (platform && !availableFilters.platforms.includes(platform))
      setPlatform(undefined);
  }, [availableFilters.platforms, platform, setPlatform]);
  useEffect(() => {
    if (brand && !availableFilters.brands.includes(brand)) setBrand(undefined);
  }, [availableFilters.brands, brand, setBrand]);
  useEffect(() => {
    if (country && !availableFilters.countries.includes(country))
      setCountry(undefined);
  }, [availableFilters.countries, country, setCountry]);
  useEffect(() => {
    if (campaign && !availableFilters.campaigns.includes(campaign))
      setCampaign(undefined);
  }, [availableFilters.campaigns, campaign, setCampaign]);

  const reset = useCallback(() => {
    setPlatform(undefined);
    setBrand(undefined);
    setCountry(undefined);
    setCampaign(undefined);
    setDateRange(undefined);
  }, [setPlatform, setBrand, setCountry, setCampaign, setDateRange]);

  const value = {
    availableFilters,
    filters,
    dateRange,
    setters: {
      setPlatform,
      setBrand,
      setCountry,
      setCampaign,
      setDateRange,
      setAnalyzed: () => {},
      setType: () => {},
    },
    reset,
    noDateRange: useMemo(() => noDateRange, [noDateRange]),
  };
  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

export interface Filters {
  platform?: Platform;
  brand?: string;
  country?: string;
  campaign?: string;
  start?: Date;
  end?: Date;
  analyzed?: boolean;
  type?: AssetLifecycle;
}
interface AvailableFilters {
  platforms: Platform[];
  brands: string[];
  countries: string[];
  campaigns: string[];
}
interface FiltersContextType {
  filters: Filters;
  dateRange?: PresetDateRange;
  setters: {
    setPlatform: React.Dispatch<React.SetStateAction<Filters['platform']>>;
    setBrand: React.Dispatch<React.SetStateAction<Filters['brand']>>;
    setCountry: React.Dispatch<React.SetStateAction<Filters['country']>>;
    setCampaign: React.Dispatch<React.SetStateAction<Filters['campaign']>>;
    setDateRange: React.Dispatch<
      React.SetStateAction<PresetDateRange | undefined>
    >;
    setAnalyzed: React.Dispatch<React.SetStateAction<Filters['analyzed']>>;
    setType: React.Dispatch<React.SetStateAction<Filters['type']>>;
  };
  availableFilters: AvailableFilters;
  reset: () => void;
  noDateRange: boolean;
}

export default function useFilters() {
  const context = useContext(FiltersContext);
  if (!context)
    throw new Error('useFilters must be used within a FiltersContextProvider');
  return context;
}

function getFilterForAvailable(
  filters: Filters,
  key: keyof Filters,
  noDateRange: boolean,
): Partial<Filters> {
  const { [key]: _, start, end, ...rest } = filters;
  return noDateRange ? rest : { ...rest, start, end };
}
