import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, TextField, Typography } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import nopicture_f from '../../assets/nopicture-f.png';
import nopicture_m from '../../assets/nopicture-m.png';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import { useAuthenticatedUser } from '../../contexts/auth';
import { Ambassador, mapCelebrityToAmbassador } from '../../models/Ambassador';
import { ApiError } from '../../services/api';
import AmbassadorModal from './AmbassadorModal';
import { countries } from './countries';

const AmbassadorsManager = () => {
  const [ambassadors, setAmbassadors] = useState<Ambassador[]>([]);
  const [loading, setLoading] = useState(false);
  const [editingAmbassador, setEditingAmbassador] = useState<Ambassador | null>(
    null,
  );
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selection, setSelection] = useState(false);
  const [selectedAmbassadors, setSelectedAmbassador] =
    useState<GridRowSelectionModel>([]);
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();
  const { api } = useAuthenticatedUser();

  const loadAmbassadors = useCallback(async () => {
    setLoading(true);
    try {
      const data = await api.ambassadors.all();
      setAmbassadors(data.map(c => mapCelebrityToAmbassador(c)));
    } catch (error) {
      console.error('Error loading ambassadors:', error);
      setApiError(error as ApiError);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setAmbassadors, api.ambassadors]);

  useEffect(() => {
    loadAmbassadors();
  }, [loadAmbassadors]);
  const filteredAmbassadors = useMemo(() => {
    return ambassadors.filter(a =>
      a.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [ambassadors, searchQuery]);

  const handleDelete = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this ambassador?'))
      return;

    try {
      setLoading(true);
      await api.ambassadors.delete(id);
      setAmbassadors(prev => prev.filter(amb => amb.celebrity_id !== id));
    } catch (error) {
      console.error('Error deleting ambassador:', error);
      setApiError(error as ApiError);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (ambassador: Ambassador) => {
    setEditingAmbassador({
      ...ambassador,
      profession:
        typeof ambassador.profession === 'string'
          ? [ambassador.profession]
          : ambassador.profession,
      nationality:
        typeof ambassador.nationality === 'string'
          ? [ambassador.nationality]
          : ambassador.nationality,
    });
    setEditDialogOpen(true);
  };

  const handleCreate = () => {
    setEditingAmbassador({
      celebrity_id: 0,
      name: '',
      gender: '',
      birth_date: new Date(),
      age: 0,
      profession: [],
      skin_tone: '',
      nationality: [],
      image_url: '',
      isAmbassador: true,
      description: '',
      appearances: [],
      detected_at: new Date(),
      thumbnail_id: '',
      video_id: '',
      vi_id: '',
      video_brand: '',
      video_country: '',
    });
    setEditDialogOpen(true);
  };

  const handleCloseEdit = () => {
    setSelection(false);
    setSelectedAmbassador([]);
    setEditingAmbassador(null);
    setEditDialogOpen(false);
  };

  const handleSaveEdit = async (updatedAmbassador: Ambassador) => {
    try {
      handleCloseEdit();
      loadAmbassadors();
    } catch (error) {
      console.error('Error saving ambassador:', error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'image_url',
      headerName: 'Photo',
      width: 100,
      renderCell: params => {
        const defaultPhoto =
          params.row.gender === 'Female' ? nopicture_f : nopicture_m;
        return (
          <img
            src={
              params.value && params.value !== 'None'
                ? params.value
                : defaultPhoto
            }
            alt={params.row.name}
            width={50}
          />
        );
      },
    },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'gender', headerName: 'Gender', flex: 1 },
    {
      field: 'birth_date',
      headerName: 'Birth Date',
      flex: 1,
      renderCell: params => (
        <Box>{params.value ? params.value.split('T')[0] : 'Unknown'}</Box>
      ),
    },
    {
      field: 'profession',
      headerName: 'Profession',
      flex: 1,
      renderCell: params => (
        <Box>
          {Array.isArray(params.value) ? params.value.join(', ') : params.value}
        </Box>
      ),
    },
    { field: 'skin_tone', headerName: 'Skin Tone', flex: 1 },
    {
      field: 'nationality',
      headerName: 'Nationality',
      flex: 1,
      renderCell: params => (
        <Box>
          {Array.isArray(params.value)
            ? params.value
                .map(code => countries[code]?.label || code)
                .join(', ')
            : countries[params.value]?.label || params.value}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label='Edit'
          onClick={() => handleEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Delete'
          onClick={() => handleDelete(params.row.celebrity_id)}
        />,
      ],
    },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          mb: 3,
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flex: '1 1 auto',
            minWidth: 400,
          }}
        >
          <Button variant='contained' color='primary' onClick={handleCreate}>
            Add New Ambassador
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setSelection(!selection);
            }}
          >
            Merge Ambassadors
          </Button>
        </Box>
        <TextField
          fullWidth
          label='Search Ambassador by name'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          sx={{
            flex: '0 1 300px',
            minWidth: 250,
          }}
        />
      </Box>
      {selection && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            border: '1px solid #90caf9', // Light blue border
            bgcolor: 'rgba(144, 202, 249, 0.2)', // Light blue background with low opacity
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1' color='textPrimary'>
            Please select 2 ambassadors to merge. You have selected:{' '}
            {selectedAmbassadors.length > 0
              ? ambassadors
                  .filter(a => selectedAmbassadors.includes(a.celebrity_id))
                  .map(a => a.name)
                  .join(', ')
              : 'None'}
            {error && (
              <Typography sx={{ color: 'red' }}>
                You can only select 2 ambassadors at a time to merge.
              </Typography>
            )}
          </Typography>
          {selectedAmbassadors.length >= 2 && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => setEditDialogOpen(true)}
              sx={{ ml: 2 }}
            >
              Merge Selected
            </Button>
          )}
        </Box>
      )}

      <ApiErrorComponent error={apiError} />

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={filteredAmbassadors}
          columns={columns}
          loading={loading}
          disableRowSelectionOnClick
          autoPageSize
          checkboxSelection={selection}
          getRowId={row => row.celebrity_id}
          onRowSelectionModelChange={newSelect => {
            if (newSelect.length > 2) {
              setError(true);
            } else {
              setError(false);
              setSelectedAmbassador(newSelect);
            }
          }}
        />
      </Box>

      <AmbassadorModal
        open={editDialogOpen}
        onClose={handleCloseEdit}
        ambassadors={
          selection
            ? ambassadors.filter(a =>
                selectedAmbassadors.includes(a.celebrity_id),
              )
            : editingAmbassador
              ? [editingAmbassador]
              : []
        }
        onSave={handleSaveEdit}
        merge={selection}
      />
    </Box>
  );
};

export default AmbassadorsManager;
