import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/PlusOne';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import nopicture_m from '../../assets/nopicture-m.png';
import { useAuthenticatedUser } from '../../contexts/auth';
import { UnknownAmbassador } from '../../models/Ambassador';
import { UnknownAmbassadorList } from '../../services/api';
import AmbassadorModal from './AmbassadorModal';

const UnknownAmbassadors = () => {
  const { api } = useAuthenticatedUser();
  const [ambassadors, setAmbassadors] = useState<UnknownAmbassadorList>({
    docs: [],
    totalPages: 0,
    totalDocs: 0,
    currentPage: 0,
    pageSize: 0,
  });
  const [page, setPage] = useState(1);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedAmbassadors, setSelectedAmbassadors] = useState<
    UnknownAmbassador[]
  >([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [ambassadorToDelete, setAmbassadorToDelete] = useState<{
    videoId: string;
    thumbnail: string;
  } | null>(null);
  const [videoBrand, setVideoBrand] = useState<string>('');
  const [videoCountry, setVideoCountry] = useState<string>('');
  const [sortBy, setSortBy] = useState<'detected_at' | 'appearances'>(
    'detected_at',
  );
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  const limit = 18;

  const navigate = useNavigate();

  const getUnknownData = useCallback(async () => {
    const data = await api.ambassadors.allUnknown(
      page,
      limit,
      videoBrand,
      videoCountry,
      sortBy,
      sortOrder,
    );
    setAmbassadors(data);
  }, [
    api.ambassadors,
    page,
    limit,
    videoBrand,
    sortBy,
    sortOrder,
    videoCountry,
  ]);

  useEffect(() => {
    getUnknownData();
  }, [getUnknownData]);

  useEffect(() => {
    const getImageUrls = async () => {
      const buffRecord: Record<string, string> = {};

      // Create an array of promises for each image load check
      const imageChecks = ambassadors.docs.map(async a => {
        if (!a.image_url) return;

        try {
          const img = new Image();
          img.src = a.image_url;
          await img.decode();
          buffRecord[a.thumbnail_id] = a.image_url;
        } catch {
          buffRecord[a.thumbnail_id] = nopicture_m; // Fallback if image fails
        }
      });
      // Wait for all image loads to complete before updating state
      await Promise.all(imageChecks);
      setImageUrls(buffRecord);
    };

    getImageUrls();
  }, [ambassadors]);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleDelete = (videoId: string, thumbnail: string) => {
    setAmbassadorToDelete({ videoId, thumbnail });
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (ambassadorToDelete) {
      // Handle delete logic here with ambassadorToDelete.videoId and ambassadorToDelete.thumbnail
      console.log('Deleting ambassador:', ambassadorToDelete);
    }
    setDeleteDialogOpen(false);
    setAmbassadorToDelete(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAmbassadorToDelete(null);
  };

  const handleEdit = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEdit = () => {
    setEditDialogOpen(false);
    setSelectedAmbassadors([]);
  };

  const handleSaveEdit = async () => {
    try {
      await getUnknownData();
      handleCloseEdit();
    } catch (error) {
      console.error('Error saving ambassador:', error);
    }
  };

  const toggleSelect = () => {
    setIsSelecting(!isSelecting);
    setSelectedAmbassadors([]);
  };

  const handleAmbassadorSelect = (ambassador: UnknownAmbassador) => {
    if (selectedAmbassadors.includes(ambassador)) {
      setSelectedAmbassadors(
        selectedAmbassadors.filter(
          a => a.thumbnail_id !== ambassador.thumbnail_id,
        ),
      );
    } else {
      setSelectedAmbassadors([...selectedAmbassadors, ambassador]);
    }
  };

  const handleBulkDelete = () => {
    // Handle bulk delete logic for selectedAmbassadors
    console.log('Deleting:', selectedAmbassadors);
  };

  const toggleSortOrder = () => {
    setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'));
    setPage(1);
  };

  const handlePlayVideo = (videoId: string) => {
    navigate(`/videos/${videoId}`);
  };

  const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoBrand(e.target.value);
    setPage(1);
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoCountry(e.target.value);
    setPage(1);
  };

  const handleSortByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(e.target.value as 'detected_at' | 'appearances');
    setPage(1);
  };

  return (
    <Box p={3}>
      <Box display='flex' alignItems='center' mb={4}>
        <Typography
          variant='h4'
          component='h1'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          UNKNOWN AMBASSADORS
        </Typography>
        <Box flexGrow={1} />

        <Box display='flex' gap={2} mr={2}>
          <FormControl size='small' sx={{ minWidth: 120 }}>
            <TextField
              select
              label='Brand'
              value={videoBrand}
              onChange={handleBrandChange}
              size='small'
            >
              <MenuItem value=''>All Brands</MenuItem>
              <MenuItem value="L'Oréal Paris">L'Oréal Paris</MenuItem>
              <MenuItem value='Lancôme'>Lancôme</MenuItem>
              <MenuItem value='Kérastase'>Kérastase</MenuItem>
              <MenuItem value='YSL Beauty'>YSL Beauty</MenuItem>
              <MenuItem value='Giorgio Armani Beauty'>
                Giorgio Armani Beauty
              </MenuItem>
              <MenuItem value='Biotherm'>Biotherm</MenuItem>
              <MenuItem value="Kiehl's">Kiehl's</MenuItem>
              <MenuItem value="L'Oréal Professionnel">
                L'Oréal Professionnel
              </MenuItem>
              <MenuItem value='Garnier'>Garnier</MenuItem>
              <MenuItem value='CeraVe'>CeraVe</MenuItem>
              <MenuItem value='Vichy'>Vichy</MenuItem>
              <MenuItem value='La Roche-Posay'>La Roche-Posay</MenuItem>
            </TextField>
          </FormControl>

          <FormControl size='small' sx={{ minWidth: 120 }}>
            <TextField
              select
              label='Country'
              value={videoCountry}
              onChange={handleCountryChange}
              size='small'
            >
              <MenuItem value=''>All Countries</MenuItem>
              {/* Add your country options here */}
            </TextField>
          </FormControl>

          <FormControl size='small' sx={{ minWidth: 150 }}>
            <TextField
              select
              label='Sort by'
              value={sortBy}
              onChange={handleSortByChange}
              size='small'
              InputProps={{
                endAdornment: (
                  <IconButton size='small' onClick={toggleSortOrder}>
                    <SortIcon
                      sx={{
                        transform:
                          sortOrder === 'desc' ? 'rotate(180deg)' : 'none',
                      }}
                    />
                  </IconButton>
                ),
              }}
            >
              <MenuItem value='detected_at'>Detection Date</MenuItem>
              <MenuItem value='appearances'>Appearances</MenuItem>
            </TextField>
          </FormControl>
        </Box>

        <Button
          variant='contained'
          onClick={toggleSelect}
          sx={{
            backgroundColor: isSelecting ? '#e5c596' : '#F5D5A6',
            color: 'black',
            '&:hover': { backgroundColor: '#e5c596' },
            mr: selectedAmbassadors.length > 0 ? 2 : 0,
          }}
        >
          {isSelecting ? 'Cancel' : 'Select'}
        </Button>
        {selectedAmbassadors.length > 0 && (
          <>
            <Button
              variant='contained'
              onClick={handleEdit}
              sx={{
                backgroundColor: '#4CAF50',
                color: 'white',
                '&:hover': { backgroundColor: '#388E3C' },
                mr: 2,
              }}
            >
              Edit Selected ({selectedAmbassadors.length})
            </Button>
            <Button
              variant='contained'
              onClick={handleBulkDelete}
              sx={{
                backgroundColor: '#ff4444',
                color: 'white',
                '&:hover': { backgroundColor: '#cc0000' },
              }}
            >
              Delete Selected ({selectedAmbassadors.length})
            </Button>
          </>
        )}
      </Box>

      <Grid container spacing={3}>
        {ambassadors.docs.map(ambassador => (
          <Grid item xs={12} sm={4} md={2} key={ambassador.thumbnail_id}>
            <Box
              sx={{
                textAlign: 'center',
                cursor: isSelecting ? 'pointer' : 'default',
                opacity:
                  isSelecting &&
                  !selectedAmbassadors.find(
                    a => a.thumbnail_id === ambassador.thumbnail_id,
                  )
                    ? 0.7
                    : 1,
              }}
              onClick={() => isSelecting && handleAmbassadorSelect(ambassador)}
            >
              <Box
                sx={{
                  width: '70%',
                  paddingBottom: '70%',
                  position: 'relative',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  mb: 1,
                  margin: 'auto',
                  border: selectedAmbassadors.find(
                    a => a.thumbnail_id === ambassador.thumbnail_id,
                  )
                    ? '3px solid #1976d2'
                    : 'none',
                  '&:hover': {
                    transform: isSelecting ? 'none' : 'scale(1.05)',
                    borderRadius: '10%',
                    transition: 'transform 0.3s ease-in-out',
                    '& .action-buttons': {
                      opacity: isSelecting ? 0 : 1,
                    },
                  },
                }}
              >
                <CardMedia
                  component='img'
                  image={imageUrls[ambassador.thumbnail_id]}
                  alt={`Ambassador ${ambassador.name}`}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                {!isSelecting && (
                  <Box
                    className='action-buttons'
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in-out',
                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handleDelete(
                          ambassador.video_id,
                          ambassador.thumbnail_id,
                        );
                      }}
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        '&:hover': { backgroundColor: 'white' },
                      }}
                      size='small'
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handleAmbassadorSelect(ambassador);
                        handleEdit();
                      }}
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        '&:hover': { backgroundColor: 'white' },
                      }}
                      size='small'
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handlePlayVideo(ambassador.video_id);
                      }}
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        '&:hover': { backgroundColor: 'white' },
                      }}
                      size='small'
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <Typography variant='subtitle1' sx={{ fontWeight: 'medium' }}>
                {ambassador.name || 'Unknown'}
              </Typography>
              <Typography variant='subtitle1' sx={{ fontWeight: 'medium' }}>
                {ambassador.appearances?.length > 0 ? (
                  <b>
                    Appears {ambassador.appearances.length}{' '}
                    {ambassador.appearances.length === 1 ? 'time' : 'times'}
                  </b>
                ) : (
                  <b>No appearances</b>
                )}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ambassador.video_brand} - {ambassador.video_country}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {ambassador.detected_at &&
                  new Date(ambassador.detected_at).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {ambassadors.docs.length === 0 && (
        <Box display='flex' justifyContent='center' mt={4}>
          <Typography variant='h6' color='text.secondary'>
            No unknown ambassadors found
          </Typography>
        </Box>
      )}

      {ambassadors.docs.length > 0 && (
        <Box display='flex' justifyContent='center' mt={4}>
          <Pagination
            count={ambassadors.totalPages}
            page={page}
            onChange={handlePageChange}
            color='primary'
          />
        </Box>
      )}

      <Box display='flex' justifyContent='center' mt={2}>
        <Typography variant='body2' color='text.secondary'>
          Page {ambassadors.currentPage} of {ambassadors.totalPages} | Total
          items: {ambassadors.totalDocs}
        </Typography>
      </Box>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this ambassador? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color='error'
            variant='contained'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AmbassadorModal
        open={editDialogOpen}
        onClose={handleCloseEdit}
        ambassadors={selectedAmbassadors}
        onSave={handleSaveEdit}
        unknown={true}
      />
    </Box>
  );
};

export default UnknownAmbassadors;
