import { AssertionError, assert } from 'chai';
import { Optional } from '../../utils/typing';
import { complexUiTypeMap, typesMap } from './typeMaps';

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type TimeRange = {
  start: number;
  end: number;
};

export type Presence = {
  timeRange: TimeRange;
  position: Position;
  metadata: Record<string, any>;
};

export type Mapping = {
  sourceProperty: string;
  targetProperty: string;
};

export type ICreativeDataStructure<S extends IStructure = IStructure> = {
  _id?: string;
  name: string;
  source: string;
  title: string;
  icon?: string;
  structure: S;
  category: string;
  highlighted?: boolean;
  showLabels?: boolean;
  order: number;
  display: boolean;
  sourceModel?: string;
  description?: string;
  showInDashboard?: boolean;
  isQualityFactor?: boolean;
  isInsightFactor?: boolean;
};

// function to return default object for ICreativeDataStructure
export function defaultCds(): ICreativeDataStructure {
  return {
    name: '',
    title: '',
    structure: {
      type: 'string',
      uiType: 'text',
      showLabels: true,
      items: {
        type: 'string',
        showLabels: true,
        uiType: 'text',
        subType: 'string',
      },
    },
    category: 'Calculated',
    display: true,
    isQualityFactor: false,
    showInDashboard: false,
    source: '',
    icon: '',
    highlighted: false,
    order: 99,
    sourceModel: '',
    description: '',
  };
}

const simpleTypes = ['string', 'number', 'boolean', 'date', 'array'] as const;
export type SimpleType = (typeof simpleTypes)[number];
export type ArrayType = 'array';
export type ObjectType = 'object';
export type ComplexType = ArrayType | ObjectType;
export const sTypes = [...simpleTypes, 'array', 'object'] as const;
export type StructureType = SimpleType | ComplexType;

export type ActualType<T extends StructureType> = {
  string: string;
  number: number;
  boolean: boolean;
  date: Date;
  array: any[];
  object: Record<string, any>;
}[T];

interface ISimpleRest<T extends SimpleType = SimpleType> {
  uiChoices?: ActualType<T>[];
  exampleValues?: ActualType<T>[];
}
interface IArrayRest<T extends IStructure = IStructure> {
  items: T;
}
interface IObjectRest<
  T extends Record<string, IStructure> = Record<string, IStructure>,
> {
  properties: T;
}

export type IStructure<U extends UiType = UiType> = {
  type: SType<U>;
  uiType: U;
  subType?: string;
  mappings?: Mapping[];
  showLabels: boolean;
} & (U extends UiType<SimpleType> ? ISimpleRest<SType<U>> : {}) &
  (U extends UiType<ArrayType> ? IArrayRest : {}) &
  (U extends UiType<ObjectType> ? IObjectRest : {});

export type SType<T extends UiType = UiType> = {
  [K in UiType]: {
    [T in StructureType]: K extends (typeof typesMap)[T][number] ? T : never;
  }[StructureType];
}[T];

export type UiType<T extends StructureType = StructureType> = {
  [K in StructureType]: (typeof typesMap)[K][number];
}[T];

export type DataType<T extends UiType> =
  T extends UiType<ComplexType>
    ? complexUiTypeMap[T]
    : T extends UiType<SimpleType>
      ? ActualType<SType<T>>
      : never;

export function isSimpleType(
  s: IStructure,
): s is IStructure<UiType<SimpleType>> {
  return simpleTypes.includes(s.type as SimpleType);
}

export const isSType = <T extends SType>(
  s: IStructure,
  type: T,
): s is IStructure<UiType<T>> => s.type === type;

export function assertIsStructure(s: IStructure): asserts s is IStructure {
  assert(typeof s === 'object');
  assert(sTypes.includes(s.type));
  if (isSimpleType(s)) assert(typesMap[s.type].includes(s.uiType as never));
  if (isSType(s, 'array')) {
    assert(typesMap[s.type].includes(s.uiType));
  }
  if (isSType(s, 'object')) {
    assert(typesMap[s.type].includes(s.uiType));
    const properties = s.properties;
    assert(typeof properties === 'object');
    for (const p of Object.values(properties)) assertIsStructure(p);
  }
}

export function assertIsCds(
  cds: Optional<ICreativeDataStructure, '_id'>,
): asserts cds is Optional<ICreativeDataStructure, '_id'> {
  assert(typeof cds === 'object');
  assertString(cds.source);
  assertString(cds.name);
  assertString(cds.title);
  assertString(cds.category);
  assert(typeof cds.display === 'boolean');
  assertString(cds.title);
  assert(!!cds.description === (typeof cds.description === 'string'));
  assert(!!cds.icon === (typeof cds.icon === 'string'));
  assert(!!cds.sourceModel === (typeof cds.sourceModel === 'string'));
  assertIsStructure(cds.structure);
  let mappings = cds.structure.mappings;
  if (mappings) {
    assert(Array.isArray(mappings));
    mappings.forEach(mapping => {
      assert(typeof mapping.sourceProperty === 'string');
      assert(typeof mapping.targetProperty === 'string');
    });
  }
}

function assertString(s: string): asserts s is string {
  assert(typeof s === 'string' && s.length > 0);
}

export function isCds(
  cds: Optional<ICreativeDataStructure, '_id'>,
): cds is Optional<ICreativeDataStructure, '_id'> {
  try {
    assertIsCds(cds);
    return true;
  } catch (e) {
    if (e instanceof AssertionError) {
      // console.warn(e.stack); //  print the stacktrace
      return false;
    } else throw e;
  }
}

export function addMapping(
  cds: ICreativeDataStructure,
  sourceProperty: string,
  targetProperty: string,
): ICreativeDataStructure {
  const newMapping: Mapping = { sourceProperty, targetProperty };
  return {
    ...cds,
    structure: {
      ...cds.structure,
      mappings: [...(cds.structure.mappings || []), newMapping],
    },
  };
}

export function removeMapping(
  cds: ICreativeDataStructure,
  sourceProperty: string,
  targetProperty: string,
): ICreativeDataStructure {
  if (!cds.structure.mappings) return cds;
  return {
    ...cds,
    structure: {
      ...cds.structure,
      mappings: cds.structure.mappings.filter(
        mapping =>
          mapping.sourceProperty !== sourceProperty ||
          mapping.targetProperty !== targetProperty,
      ),
    },
  };
}

export function updateMapping(
  cds: ICreativeDataStructure,
  oldSourceProperty: string,
  oldTargetProperty: string,
  newSourceProperty: string,
  newTargetProperty: string,
): ICreativeDataStructure {
  if (!cds.structure.mappings) return cds;
  return {
    ...cds,
    structure: {
      ...cds.structure,
      mappings: cds.structure.mappings.map(mapping =>
        mapping.sourceProperty === oldSourceProperty &&
        mapping.targetProperty === oldTargetProperty
          ? {
              sourceProperty: newSourceProperty,
              targetProperty: newTargetProperty,
            }
          : mapping,
      ),
    },
  };
}

export function getMappings(cds: ICreativeDataStructure): Mapping[] {
  return cds.structure.mappings || [];
}
