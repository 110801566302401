import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import useCdStructures from '../../contexts/cdStructures';
import { useDebounce } from '../../hooks/useDebounce';
import { IVideoSummary } from '../../models';
import { ICreativeDataStructure } from '../../models/creativeDataStructure';
import { ApiError } from '../../services/api';
import useFilters, { Filters } from '../FiltersBar/context';

interface DashboardContextType {
  filters: Filters;
  selectedCd: ICreativeDataStructure[];
  results?: IVideoSummary;
  isLoading: boolean;
  error: ApiError | null;
}

const DashboardContext = createContext<DashboardContextType | null>(null);

export const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const { dashboardCdStructures } = useCdStructures();
  const { filters } = useFilters();
  const [results, setResults] = useState<IVideoSummary>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ApiError | null>(null);

  const search = useCallback(() => {
    setIsLoading(true);
    const { type, ...rest } = filters;
    return api.inflights
      .summary(rest)
      .then(setResults)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [api, filters]);

  const debouncedSearch = useDebounce(search, 50);

  useEffect(() => {
    debouncedSearch();
  }, [debouncedSearch]);

  const value: DashboardContextType = {
    filters,
    selectedCd: dashboardCdStructures,
    results,
    isLoading,
    error,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export default function useDashboard() {
  const context = useContext(DashboardContext);
  if (!context)
    throw new Error('useDashboard must be used within a DashboardProvider');
  return context;
}
