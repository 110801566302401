import {
  AddCircle,
  AdsClick,
  CurrencyExchange,
  MoreTime,
  MoreVert,
  PlayCircleOutline,
  SensorOccupied,
  SvgIconComponent,
  UploadFileRounded,
  VisibilityOutlined,
  WebOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  capitalize,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  SvgIcon,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DynamicImage from '../../components/DynamicImage';
import StandardModal from '../../components/Modal';
import { useAuthenticatedUser } from '../../contexts/auth';
import {
  compareIndexation,
  Indexation,
  IndexationStatus,
  PerfKey,
} from '../../models';
import {
  IPreflightVideo,
  isAnalyzed,
  WithAuthor,
  WithIndexation,
} from '../../models/assets';
import { PaginatedResult, PaginationOptions } from '../../models/pagination';
import { ApiError, parseApiError } from '../../services/api';
import dates from '../../utils/dates';
import FiltersBar from '../FiltersBar';
import useFilters, { FiltersContextProvider } from '../FiltersBar/context';
import NewPreflight from './Create';
import BulkCreatePreflight from './CreateBulk';

// Config
const scoreBarHeight = '8px';
const scoreBarBgColor = '#e0e0e0';
const scoreBarColors = ['#E85B86', '#7BC7D9', '#7BD9BC'];

const statusPointsSize = '8px';
const statusesColors: Record<IndexationStatus, TypographyProps['color']> = {
  pending: theme => theme.palette.warning.main,
  completed: theme => theme.palette.success.main,
  failed: theme => theme.palette.error.main,
  processing: theme => theme.palette.primary.main,
};

const objectiveIconSize = '20px';
const objectivesIcons: Record<PerfKey, SvgIconComponent> = {
  CTR: AdsClick,
  ConversionRate: CurrencyExchange,
  RetentionRate: SensorOccupied,
  WatchTime: MoreTime,
  views: VisibilityOutlined,
  impressions: WebOutlined,
};
const AllPreflights: React.FC = () => {
  return (
    <FiltersContextProvider type='preflight' noDateRange>
      <AllPreflightsInner />
    </FiltersContextProvider>
  );
};
const AllPreflightsInner: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { api } = useAuthenticatedUser();
  const [openForm, setOpenForm] = useState<'bulk' | 'one' | null>(null);
  const [results, setResults] = useState<
    PaginatedResult<WithIndexation<WithAuthor<IPreflightVideo>>>
  >({
    docs: [],
    totalDocs: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { filters } = useFilters();
  const preflightFilters = useMemo(() => {
    const { start, end, type, ...rest } = filters;
    return rest;
  }, [filters]);

  const setPagination = useCallback(
    (pagination: PaginationOptions) => {
      setSearchParams({
        page: pagination.page.toString(),
        pageSize: pagination.pageSize.toString(),
      });
    },
    [setSearchParams],
  );

  const pagination = useMemo(() => {
    const page = parseInt(searchParams.get('page') || '0');
    const pageSize = parseInt(searchParams.get('pageSize') || '10');
    return { page, pageSize };
  }, [searchParams]);

  const fetch = useCallback(() => {
    setLoading(true);
    api.preflight
      .all({ ...preflightFilters, ...pagination })
      .then(setResults)
      .finally(() => setLoading(false));
  }, [api, pagination, preflightFilters]);

  useEffect(fetch, [fetch]);

  const postCreate = useCallback(() => {
    fetch();
    setOpenForm(null);
  }, [fetch]);

  const handleRowClick = (params: any) => {
    if (params.row.creativeData !== undefined)
      navigate(`/preflights/${params.row._id}`);
  };

  const columns: GridColDef<WithIndexation<WithAuthor<IPreflightVideo>>>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 4,
      renderCell: ({ row }) => (
        <Title
          title={row.metadata.name}
          previewImage={row.metadata.thumbnailUrl}
        />
      ),
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'started',
      headerName: 'Started',
      flex: 2,
      valueGetter: () => '',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'platform',
      headerName: 'Platform',
      flex: 2,
      renderCell: ({ row }) => capitalize(row.metadata.platform),
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'brand',
      headerName: 'Brand',
      flex: 2,
      renderCell: ({ row }) => row.metadata.brand,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 2,
      renderCell: ({ row }) => row.metadata.country,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'campaignName',
      headerName: 'Campaign',
      flex: 2.5,
      renderCell: ({ row }) => row.metadata.campaign,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 2,
      renderCell: ({ row }) => row.metadata.source,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'author',
      headerName: 'Author',
      flex: 2,
      valueGetter: (cd: WithAuthor['author']) => cd.Username,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'creativeData',
      headerName: 'Duration',
      flex: 2,
      valueGetter: (cd: IPreflightVideo['creativeData']) =>
        cd?.general.videoDuration,
      valueFormatter: (value?: number) =>
        value && dates.formatDurationHumanly(value),
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'brandScore',
      headerName: 'Brand Score',
      flex: 2,
      renderCell: ({ row }) =>
        isAnalyzed(row) && <GuidelineScore preflight={row} type='brand' />,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'platformScore',
      headerName: 'Platform Score',
      flex: 2,
      renderCell: ({ row }) =>
        isAnalyzed(row) && <GuidelineScore preflight={row} type='platform' />,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'indexation',
      headerName: 'Status',
      flex: 1.5,
      renderCell: ({ row }) => (
        <IndexationProgress indexation={row.indexation} />
      ),
      sortComparator: compareIndexation,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'objectives',
      headerName: 'Objectives',
      flex: 2.5,
      renderCell: ({ row }) => (
        <Objectives objectives={row.metadata.objectives} />
      ),
      sortComparator: (a, b) => a.length - b.length,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      flex: 0.5,
      renderCell: ({ row }) => <Actions preflight={row} setPrelights={fetch} />,
      disableColumnMenu: true,
    },
  ];

  return (
    <Stack height='100%'>
      <FiltersBar>
        <Stack direction='row' spacing={2}>
          <Button
            variant='contained'
            startIcon={<AddCircle />}
            sx={{
              bgcolor: 'black',
              color: 'white',
              px: 2,
              borderRadius: '14px',
              textTransform: 'none',
            }}
            onClick={() => setOpenForm('one')}
          >
            New Preflight
          </Button>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<UploadFileRounded />}
            sx={{
              bgcolor: 'black',
              color: 'white',
              px: 2,
              borderRadius: '14px',
              textTransform: 'none',
            }}
            onClick={() => setOpenForm('bulk')}
          >
            Bulk Preflight
          </Button>
        </Stack>
      </FiltersBar>
      <Box flexGrow={1} minHeight={0} mt={2}>
        <DataGrid
          rows={results.docs}
          columns={columns}
          rowHeight={75}
          rowCount={results.totalDocs}
          loading={loading}
          paginationMode='server'
          paginationModel={pagination}
          onPaginationModelChange={setPagination}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSorting
          getRowId={row => row._id}
          onRowClick={handleRowClick}
          getRowClassName={({ row }) =>
            row.creativeData ? 'preflightAnalyzed' : ''
          }
          slots={{
            noRowsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No results found
              </Box>
            ),
            noResultsOverlay: () => (
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No results found
              </Box>
            ),
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                started: false,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            '--DataGrid-containerBackground': 'transparent !important',
            '--DataGrid-rowBorderColor': 'transparent !important',
            border: 'none',
            '& .MuiDataGrid-row.preflightAnalyzed': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              bgcolor: '#FAFAFA',
              borderRadius: '30px',
            },
          }}
        />
      </Box>
      <StandardModal
        open={openForm === 'one'}
        onClose={() => setOpenForm(null)}
      >
        <NewPreflight
          postCreate={postCreate}
          onClose={() => setOpenForm(null)}
        />
      </StandardModal>
      <StandardModal
        open={openForm === 'bulk'}
        onClose={() => setOpenForm(null)}
      >
        <BulkCreatePreflight
          postCreate={postCreate}
          onClose={() => setOpenForm(null)}
        />
      </StandardModal>
    </Stack>
  );
};
export default AllPreflights;

const Title: React.FC<{ title: string; previewImage?: string }> = ({
  title,
  previewImage,
}) => {
  return (
    <Box display='flex' alignItems='center' height='100%'>
      <DynamicImage
        alt={title}
        src={previewImage}
        height='70px'
        width='70px'
        FallbackIcon={PlayCircleOutline}
      />
      <Typography title={title} variant='body2' noWrap ml={1}>
        {title}
      </Typography>
    </Box>
  );
};

const GuidelineScore: React.FC<{
  preflight: IPreflightVideo;
  type: 'brand' | 'platform';
}> = ({ preflight, type }) => {
  const { api } = useAuthenticatedUser();
  const [score, setScore] = useState<number>();
  const [error, setError] = useState<ApiError>();
  useEffect(() => {
    if (type === 'brand')
      api.videos.scores
        .brand(preflight._id)
        .then(score => setScore(score.totalScore))
        .catch(setError);
    else
      api.videos.scores
        .platform(preflight._id)
        .then(score => setScore(score.totalScore))
        .catch(setError);
  }, [api, preflight._id, type]);
  if (error) {
    console.warn(parseApiError(error));
    return <>Not Available</>;
  }
  if (!score) return null;
  return <Score value={score} />;
};

const Score: React.FC<{ value: number }> = ({ value }) => {
  const percentage = `${value * 100}%`;
  const color =
    scoreBarColors[
      Math.min(
        Math.floor(value * scoreBarColors.length),
        scoreBarColors.length - 1,
      )
    ];
  return (
    <CellBox>
      <Tooltip title={percentage}>
        <Box
          sx={{
            width: '100%',
            height: scoreBarHeight,
            bgcolor: scoreBarBgColor,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              width: percentage,
              height: '100%',
              bgcolor: color,
              borderRadius: 2,
            }}
          />
        </Box>
      </Tooltip>
    </CellBox>
  );
};

const IndexationProgress: React.FC<{ indexation: Indexation }> = ({
  indexation,
}) => {
  const { download_status, avi_status, postprocess_status } = indexation;
  return (
    <CellBox justifyContent='space-evenly'>
      <StatusPoint title='Download status' status={download_status} />
      <StatusPoint title='Process status' status={avi_status} />
      <StatusPoint title='Post process status' status={postprocess_status} />
    </CellBox>
  );
};

const StatusPoint: React.FC<{ title: string; status: IndexationStatus }> = ({
  title,
  status,
}) => {
  return (
    <Tooltip title={`${title}: ${capitalize(status)}`}>
      <Box
        sx={{
          width: statusPointsSize,
          height: statusPointsSize,
          borderRadius: '50%',
          bgcolor: statusesColors[status],
        }}
      />
    </Tooltip>
  );
};

const Objectives: React.FC<{ objectives: PerfKey[] }> = ({ objectives }) => {
  return (
    <CellBox justifyContent='left'>
      {objectives.map((key, index) => (
        <Tooltip key={index} title={key}>
          <SvgIcon
            sx={{ height: objectiveIconSize, width: objectiveIconSize, mr: 1 }}
          >
            {React.createElement(objectivesIcons[key])}
          </SvgIcon>
        </Tooltip>
      ))}
    </CellBox>
  );
};

const Actions: React.FC<{
  preflight: IPreflightVideo;
  setPrelights: React.Dispatch<React.SetStateAction<IPreflightVideo[]>>;
}> = ({ preflight, setPrelights }) => {
  const { api } = useAuthenticatedUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const deletePreflight = useCallback(() => {
    api.preflight.delete(preflight._id).then(() => {
      setPrelights(prev => prev.filter(p => p._id !== preflight._id));
      handleClose();
    });
  }, [api.preflight, preflight._id, setPrelights]);

  return (
    <div>
      <IconButton size='small' sx={{ p: 0.5 }} onClick={handleClick}>
        <MoreVert sx={{ fontSize: 18 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <Button color='error' variant='contained' onClick={deletePreflight}>
            Delete
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

const CellBox = styled(Box)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '0 8px',
});
