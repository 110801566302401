export interface Ambassador {
  celebrity_id: number;
  image_url: string;
  description: string;
  name: string;
  birth_date: Date;
  age: number;
  gender: string;
  appearances: {
    timeRange: {
      start: number;
      end: number;
    };
  }[];
  isAmbassador: boolean;
  nationality: string[]; // Changed to string[] since it's comma-separated
  profession: string[]; // Changed to string[] since it's comma-separated
  skin_tone: string;
  thumbnail_id: string;
  detected_at?: Date;
  created_at?: string;
  video_id: string;
  vi_id: string;
  video_brand: string;
  video_country: string;
}

export interface UnknownAmbassador extends Ambassador {
  asset_id: string;
  source: string;
  unknown_id: number;
}

export interface UnknownIdentifier {
  asset_id: string;
  source: string;
  unknown_id: number;
  blob_storage_path: string;
}

export interface UnknownPayload {
  asset_id: string;
  source: string;
  unknown_id: number;
  unknown_data: Celebrity;
  blob_storage_path: string;
  additional_faces: File[];
}

export type AmbassadorType = Ambassador | UnknownAmbassador;
export type AmbassadorList = AmbassadorType[];

export interface Celebrity {
  //SQL ambassador structure
  celebrity_id: number;
  name: string;
  birth_date: Date;
  gender: string;
  nationality: string;
  job: string;
  skin_tone: number;
  image_url: string;
  is_ambassador: boolean;
  is_validated: boolean;
  avi_id: string;
  created_at?: string;
  nicknames?: string;
}

export interface People {
  //MongoDB ambassador structure in creativeData
  id: number;
  name: string;
  image: string;
  knownPersonId: string;
  age: number;
  gender: string;
  profession: string;
  nationality: string;
  skinTone: number;
  isVerified: boolean;
  confidence: number;
  celebrityId: number;
  appearances: {
    timeRange: {
      start: number;
      end: number;
    };
  }[];
  thumbnailId: string;
}

export function mapPeopleToAmbassador(people: People): Ambassador {
  const birthDate = new Date();
  birthDate.setFullYear(birthDate.getFullYear() - people.age); // Calculate birth year

  return {
    celebrity_id: people.celebrityId,
    image_url: people.image,
    description: '', // No equivalent field, leaving as empty
    name: people.name,
    birth_date: birthDate,
    age: people.age,
    gender: people.gender,
    appearances: people.appearances,
    isAmbassador: people.isVerified,
    nationality: people.nationality ? people.nationality.split(',') : [],
    profession: people.profession ? people.profession.split(',') : [],
    skin_tone: people.skinTone ? people.skinTone.toString() : '',
    thumbnail_id: people.thumbnailId,
    video_id: '', // No equivalent field, setting as empty
    vi_id: '', // No equivalent field, setting as empty
    video_brand: '', // No equivalent field, setting as empty
    video_country: '', // No equivalent field, setting as empty
  };
}

export function mapCelebrityToAmbassador(celebrity: Celebrity): Ambassador {
  return {
    celebrity_id: celebrity.celebrity_id,
    image_url: celebrity.image_url,
    description: '', // No equivalent field, leaving as empty
    name: celebrity.name,
    birth_date: celebrity.birth_date,
    age:
      new Date().getFullYear() - new Date(celebrity.birth_date).getFullYear(), // Calculate age
    gender: celebrity.gender,
    appearances: [], // No equivalent field, leaving as empty
    isAmbassador: celebrity.is_ambassador,
    nationality: celebrity.nationality ? celebrity.nationality.split(',') : [],
    profession: celebrity.job ? celebrity.job.split(',') : [],
    skin_tone: celebrity.skin_tone ? celebrity.skin_tone.toString() : '',
    created_at: celebrity.created_at,
    thumbnail_id: '', // No equivalent field, leaving as empty
    video_id: '', // No equivalent field, leaving as empty
    vi_id: celebrity.avi_id, // Mapping avi_id to vi_id
    video_brand: '', // No equivalent field, leaving as empty
    video_country: '', // No equivalent field, leaving as empty
  };
}

export function combinePeopleAndCelebrityToAmbassador(
  people: People,
  celebrity: Celebrity,
): Ambassador {
  return {
    celebrity_id: celebrity.celebrity_id || people.celebrityId,
    image_url: celebrity.image_url || people.image,
    description: '', // No equivalent field in either, leaving empty
    name: celebrity.name || people.name,
    birth_date:
      celebrity.birth_date ||
      new Date(new Date().getFullYear() - people.age, 0, 1),
    age:
      people.age ||
      new Date().getFullYear() - new Date(celebrity.birth_date).getFullYear(), // Prefer People’s age
    gender: celebrity.gender || people.gender,
    appearances: people.appearances || [],
    isAmbassador: celebrity.is_ambassador,
    nationality: (celebrity.nationality || people.nationality).split(','),
    profession: (celebrity.job || people.profession).split(','),
    skin_tone: celebrity.skin_tone ? celebrity.skin_tone.toString() : 'Unknown',
    thumbnail_id: people.thumbnailId || '', // Taking from People
    created_at: celebrity.created_at,
    video_id: '', // No equivalent field, leaving empty
    vi_id: celebrity.avi_id, // Mapping avi_id to vi_id
    video_brand: '', // No equivalent field, leaving empty
    video_country: '', // No equivalent field, leaving empty
  };
}

export function ambassadorToCelebrity(ambassador: Ambassador): Celebrity {
  return {
    celebrity_id: ambassador.celebrity_id,
    name: ambassador.name,
    birth_date: ambassador.birth_date,
    gender: ambassador.gender,
    nationality:
      typeof ambassador.nationality === 'string'
        ? ambassador.nationality
        : ambassador.nationality.join(','),
    job:
      typeof ambassador.profession === 'string'
        ? ambassador.profession
        : ambassador.profession.join(','),
    skin_tone: parseInt(ambassador.skin_tone, 10),
    image_url: ambassador.image_url,
    is_ambassador: ambassador.isAmbassador ? ambassador.isAmbassador : false,
    is_validated: false,
    avi_id: ambassador.vi_id,
  };
}

export function ambassadorToPeople(ambassador: Ambassador): People {
  return {
    id: ambassador.celebrity_id,
    name: ambassador.name,
    image: ambassador.image_url,
    knownPersonId: ambassador.vi_id,
    age: ambassador.age,
    gender: ambassador.gender,
    profession:
      typeof ambassador.profession === 'string'
        ? ambassador.profession
        : ambassador.profession.join(','),
    nationality:
      typeof ambassador.nationality === 'string'
        ? ambassador.nationality
        : ambassador.nationality.join(','),
    skinTone: parseInt(ambassador.skin_tone, 10),
    isVerified: false,
    confidence: 0,
    celebrityId: ambassador.celebrity_id,
    appearances: ambassador.appearances,
    thumbnailId: ambassador.thumbnail_id,
  };
}
