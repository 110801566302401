import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthenticatedUser } from '../../contexts/auth';
import { useDebounce } from '../../hooks/useDebounce';
import { IAnalyzed, IInflightVideo } from '../../models/assets';
import { Pagination } from '../../models/pagination';
import useFilters from '../FiltersBar/context';

const defaultPageSize = 12;
const LibraryContext = createContext<LibraryContextType | null>(null);

export const LibraryProvider: FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const { filters } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayMode, setDisplayMode] = useState<DisplayMode>('grid');
  const [results, setResults] = useState<IAnalyzed<IInflightVideo>[]>([]);
  const [pagination, _setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: defaultPageSize,
    totalDocs: 0,
    totalPages: 100000,
  });
  const [isLoading, setIsLoading] = useState(true);
  const setPagination: React.Dispatch<SetStateAction<Pagination>> = (
    value: SetStateAction<Pagination>,
  ) => {
    _setPagination(prevState => {
      const v = typeof value === 'function' ? value(prevState) : value;
      if (deepEqual(prevState, v)) {
        //cancel the update
        return prevState;
      } else {
        return v;
      }
    });
  };

  const search = useCallback(() => {
    const { currentPage, pageSize } = pagination;
    setIsLoading(true);
    const { type, ...rest } = filters;
    return api.inflights
      .all<
        IAnalyzed<IInflightVideo>
      >({ ...rest, pageSize, page: currentPage - 1 })
      .then(({ docs, currentPage, ...rest }) => {
        setResults(docs);
        setPagination({ currentPage: currentPage + 1, ...rest });
        setIsLoading(false);
      });
  }, [api, filters, pagination]);

  const setPage = useCallback(
    (page: number) => {
      setPagination(p => ({ ...p, currentPage: page }));
      setSearchParams(prev => {
        const newParams = new URLSearchParams(prev);
        newParams.set('page', page.toString());
        return newParams;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (
      pagination.currentPage > pagination.totalPages &&
      pagination.totalPages > 0
    )
      setPage(pagination.totalPages);
  }, [pagination, setPage]);

  const debouncedSearch = useDebounce(search, 50);

  useEffect(() => {
    debouncedSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    const p = searchParams.get('page');
    if (p) setPage(parseInt(p));
  }, [setPage, searchParams]);

  const value = {
    displayMode,
    setDisplayMode,
    results,
    setPage,
    pagination,
    isLoading,
  };
  return (
    <LibraryContext.Provider value={value}>{children}</LibraryContext.Provider>
  );
};

type DisplayMode = 'grid' | 'list';

interface LibraryContextType {
  displayMode: DisplayMode;
  setDisplayMode: Dispatch<React.SetStateAction<DisplayMode>>;
  results: IAnalyzed<IInflightVideo>[];
  setPage: (page: number) => void;
  pagination: Pagination;
  isLoading: boolean;
}

export default function useLibrary() {
  const context = useContext(LibraryContext);
  if (!context)
    throw new Error('useLibrary must be used within a LibraryProvider');
  return context;
}

var deepEqual = function (x: any, y: any) {
  if (x === y) {
    return true;
  } else if (
    typeof x == 'object' &&
    x != null &&
    typeof y == 'object' &&
    y != null
  ) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};
