import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { countries, CountryMap } from '../features/Ambassadors/countries';
import {
  Ambassador,
  AmbassadorList,
  AmbassadorType,
} from '../models/Ambassador';
import { ApiError } from '../services/api';
import ApiErrorComponent from './ApiErrorComponent';

interface AmbassadorEditProps {
  ambassadors: AmbassadorList;
  onApply: (a: AmbassadorType, files: File[]) => Promise<void>;
  onCancel: () => void;
  mode: 'search' | 'edit' | 'create' | 'createFromUnknown';
  loading: Boolean;
  apiError: ApiError | undefined;
}

const AmbassadorEdit: React.FC<AmbassadorEditProps> = ({
  ambassadors,
  onApply,
  onCancel,
  mode,
  loading,
  apiError,
}) => {
  const [editedAmbassador, setEditedAmbassador] =
    useState<AmbassadorType | null>(null);
  const [trainingImages, setTrainingImages] = useState<File[]>([]);
  const [error, setError] = useState<boolean>(false);
  const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB
  const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png']; // Allowed file types

  useEffect(() => {
    if (ambassadors && ambassadors.length > 0) {
      setEditedAmbassador({
        ...ambassadors[0],
        profession:
          typeof ambassadors[0].profession === 'string'
            ? [ambassadors[0].profession]
            : ambassadors[0].profession,
        nationality:
          typeof ambassadors[0].nationality === 'string'
            ? [ambassadors[0].nationality]
            : ambassadors[0].nationality,
      });
    }
  }, [ambassadors, mode]);

  const handleFieldChange = (field: keyof Ambassador, value: any) => {
    if (editedAmbassador) {
      setEditedAmbassador({
        ...editedAmbassador,
        [field]: value,
      });
    }
  };
  const handleChangeDate = (value: any) => {
    if (editedAmbassador) {
      const date = new Date(Date.now()).getFullYear();
      const Bdate = new Date(value);
      setEditedAmbassador({
        ...editedAmbassador,
        birth_date: Bdate,
        age: date - Bdate.getFullYear(),
      });
    }
  };

  const handleImageDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const validFiles = files.filter(file => {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        alert(
          `❌ Invalid file type: ${file.name}. Only JPG, PNG, and WEBP are allowed.`,
        );
        return false;
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert(
          `❌ ${file.name} is too large. Maximum size is ${MAX_FILE_SIZE_MB}MB.`,
        );
        return false;
      }
      return true;
    });
    setTrainingImages([...trainingImages, ...validFiles]);
  };

  const handleRemoveImage = (index: number) => {
    setTrainingImages(trainingImages.filter((_, i) => i !== index));
  };

  const handlePlayVideo = () => {
    if (editedAmbassador?.video_id) {
      window.open(`/videos/${editedAmbassador.video_id}`, '_blank');
    }
  };

  const handleOnApply = () => {
    if (
      editedAmbassador === null ||
      ((mode === 'createFromUnknown' || mode === 'create') &&
        trainingImages.length === 0)
    )
      setError(true);
    else {
      setError(false);
      onApply(editedAmbassador, trainingImages);
    }
  };

  const countryOptions = useMemo(
    () =>
      Object.entries(countries as CountryMap).map(([code, label]) => ({
        code,
        label: label.label,
      })),
    [],
  );

  return (
    <>
      {mode === 'edit' && editedAmbassador && (
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box
              component='img'
              src={editedAmbassador.image_url}
              sx={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                objectFit: 'cover',
                mr: 2,
              }}
            />
            <Box>
              <Typography variant='subtitle1'>
                {editedAmbassador.name || ''}
              </Typography>
              {editedAmbassador.created_at && (
                <Typography variant='body2' color='text.secondary'>
                  Added on{' '}
                  {new Date(editedAmbassador.created_at).toLocaleDateString(
                    'en-US',
                    {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    },
                  )}
                </Typography>
              )}
              <Typography variant='body2' color='text.secondary'>
                {editedAmbassador.appearances?.length || 0} Appearances
              </Typography>
              {editedAmbassador.video_id && (
                <Button
                  startIcon={<PlayArrowIcon />}
                  onClick={handlePlayVideo}
                  sx={{ mt: 1 }}
                  size='small'
                >
                  Play Video
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 3,
          mb: 4,
        }}
      >
        <Box>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            Ambassador Name
          </Typography>
          <TextField
            fullWidth
            size='small'
            value={editedAmbassador?.name || ''}
            onChange={e => {
              handleFieldChange('name', e.target.value);
            }}
            placeholder='Enter name'
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {/* Birth Date Section */}
          <Box sx={{ flex: 1 }}>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
              Birth date
            </Typography>
            <TextField
              type='date'
              fullWidth
              size='small'
              value={
                editedAmbassador?.birth_date
                  ? new Date(editedAmbassador.birth_date)
                      .toISOString()
                      .split('T')[0]
                  : ''
              }
              onChange={e => handleChangeDate(e.target.value)}
              placeholder='Unknown'
            />
          </Box>
        </Box>

        <Box>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            Gender
          </Typography>
          <TextField
            select
            fullWidth
            size='small'
            value={editedAmbassador?.gender || 'Unknown'}
            onChange={e => handleFieldChange('gender', e.target.value)}
          >
            <MenuItem value='Unknown'>Unknown</MenuItem>
            <MenuItem value='Female'>Female</MenuItem>
            <MenuItem value='Male'>Male</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
          </TextField>
        </Box>

        <Box>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            Nationalities
          </Typography>
          <Autocomplete
            multiple
            size='small'
            options={countryOptions}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.label
            }
            value={
              editedAmbassador?.nationality?.length
                ? editedAmbassador.nationality.map(code => ({
                    code,
                    label: countries[code]?.label || code,
                  }))
                : [{ code: 'unknown', label: 'Unknown' }]
            }
            onChange={(_, newValue) => {
              handleFieldChange(
                'nationality',
                newValue.map(v => (v.code === 'unknown' ? null : v.code)),
              );
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Box>

        <Box>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            SkinTone
          </Typography>
          <TextField
            select
            fullWidth
            size='small'
            value={editedAmbassador?.skin_tone || 'Unknown'}
            onChange={e => handleFieldChange('skin_tone', e.target.value)}
          >
            <MenuItem value='Unknown'>Unknown</MenuItem>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(tone => (
              <MenuItem key={tone} value={tone}>
                {tone}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box>
          <Typography variant='body2' color='text.secondary' sx={{ mb: 1 }}>
            Profession
          </Typography>
          <Autocomplete
            multiple
            freeSolo
            size='small'
            options={[]}
            value={
              editedAmbassador?.profession?.length
                ? editedAmbassador.profession
                : ['Unknown']
            }
            onChange={(_, newValue) => {
              handleFieldChange(
                'profession',
                newValue.filter(profession => profession !== 'Unknown'),
              );
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Box>
      </Box>

      <Box>
        {mode === 'edit' ? (
          <Typography variant='subtitle2' sx={{ mb: 2 }}>
            Change profile picture
          </Typography>
        ) : (
          <Typography variant='subtitle2' sx={{ mb: 2 }}>
            Training Images{' '}
          </Typography>
        )}
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 2,
            p: 3,
            backgroundColor: '#F9FAFB',
            textAlign: 'center',
            mb: 1,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
          onDrop={handleImageDrop}
          onDragOver={e => e.preventDefault()}
        >
          {trainingImages.length > 0 ? (
            trainingImages.map((image, index) => (
              <Box
                key={index}
                component='div'
                sx={{
                  position: 'relative',
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  mr: 2,
                  '&:hover .overlay': {
                    opacity: 1, // Show overlay on hover
                  },
                }}
                onClick={() => handleRemoveImage(index)}
              >
                <Box
                  component='img'
                  src={URL.createObjectURL(image)}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />

                <Box
                  className='overlay'
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 'bold',
                    opacity: 0,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  Remove
                </Box>
              </Box>
            ))
          ) : (
            <Typography>Drag and Drop Images Here</Typography>
          )}
        </Box>
        <Typography variant='caption' color='text.secondary'>
          Minimum of 1 pictures required. Supported formats: PNG/JPEG. Maximum
          file size: 10 MB per file.
        </Typography>
      </Box>
      {error && (
        <Typography color='red'>
          Please fill all the fields and provide at least 1 image
        </Typography>
      )}

      <ApiErrorComponent error={apiError} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
          mt: 4,
          pt: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              onClick={onCancel}
              variant='outlined'
              sx={{
                borderRadius: '8px',
                px: 3,
                borderColor: '#D0D5DD',
                color: 'text.primary',
              }}
            >
              Cancel
            </Button>
            {editedAmbassador && (
              <Button
                onClick={handleOnApply}
                variant='contained'
                sx={{
                  borderRadius: '8px',
                  px: 3,
                  backgroundColor: '#7F56D9',
                  '&:hover': {
                    backgroundColor: '#6941C6',
                  },
                }}
              >
                {mode === 'edit' ? 'Save Changes' : 'Add as New Ambassador'}
              </Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AmbassadorEdit;
