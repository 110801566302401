import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { countries, CountryMap } from '../features/Ambassadors/countries';
import { Ambassador, AmbassadorType } from '../models/Ambassador';
import { ApiError } from '../services/api';
import ApiErrorComponent from './ApiErrorComponent';

interface AmbassadorMergeProps {
  ambassador1: AmbassadorType;
  ambassador2: AmbassadorType;
  onMerge: (mergedData: AmbassadorType, otherId: number) => Promise<void>;
  onCancel: () => void;
  loading: boolean;
  apiError: ApiError | undefined;
}

interface MergedAmbassador extends Ambassador {
  merged_from: number[];
}

const AmbassadorMerge: React.FC<AmbassadorMergeProps> = ({
  ambassador1,
  ambassador2,
  onMerge,
  onCancel,
  loading,
  apiError,
}) => {
  const [mergedAmbassador, setMergedAmbassador] = useState<MergedAmbassador>({
    ...ambassador1,
    merged_from: [ambassador1.celebrity_id, ambassador2.celebrity_id],
  });
  const [selectedImageSource, setSelectedImageSource] = useState<
    'ambassador1' | 'ambassador2' | 'manual'
  >('manual');

  const countryOptions = useMemo(
    () =>
      Object.entries(countries as CountryMap).map(([code, label]) => ({
        code,
        label: label.label,
      })),
    [],
  );

  useEffect(() => {
    setMergedAmbassador({
      ...ambassador1,
      merged_from: [ambassador1.celebrity_id, ambassador2.celebrity_id],
    });
  }, [ambassador1, ambassador2]);

  const handleFieldChange = (field: keyof Ambassador, value: any) => {
    setMergedAmbassador(prev => ({
      ...prev,
      [field]: value,
    }));
  };
  console.log(mergedAmbassador);

  const handleChangeDate = (value: string) => {
    const date = new Date(Date.now()).getFullYear();
    const Bdate = new Date(value);
    setMergedAmbassador(prev => ({
      ...prev,
      birth_date: Bdate,
      age: date - Bdate.getFullYear(),
    }));
  };

  const handleImageSelect = (source: 'ambassador1' | 'ambassador2') => {
    setSelectedImageSource(source);
    if (source === 'ambassador1') {
      handleFieldChange('image_url', ambassador1.image_url);
    } else {
      handleFieldChange('image_url', ambassador2.image_url);
    }
  };

  const getDiffStyle = (field: keyof AmbassadorType) => {
    if (
      Array.isArray(ambassador1[field]) &&
      Array.isArray(ambassador2[field])
    ) {
      if (
        JSON.stringify(ambassador1[field]) ===
        JSON.stringify(ambassador2[field])
      )
        return {};
    } else {
      if (ambassador1[field] === ambassador2[field]) return {};
    }
    if (field === 'image_url')
      return {
        border: '2px solid #ff1744',
        borderRadius: '4px',
        padding: '4px',
        marginBottom: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    else
      return {
        border: '2px solid #ff1744',
        borderRadius: '4px',
        padding: '4px',
        marginBottom: '4px',
      };
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 2fr',
          gap: 3,
          mb: 4,
        }}
      >
        <Box>
          <Typography variant='subtitle1' sx={{ mb: 2 }}>
            Ambassador 1
          </Typography>

          <Box sx={getDiffStyle('name')}>
            <Typography variant='body2'>Name: {ambassador1.name}</Typography>
          </Box>

          <Box sx={getDiffStyle('image_url')} mt={2}>
            <Button onClick={() => handleImageSelect('ambassador1')}>
              <Box
                component='img'
                src={ambassador1.image_url}
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  mt: 1,
                  border:
                    selectedImageSource === 'ambassador1'
                      ? '2px solid green'
                      : 'none',
                }}
              />
            </Button>
          </Box>

          <Box sx={getDiffStyle('birth_date')} mt={2}>
            <Typography variant='body2'>
              Birth Date:{' '}
              {ambassador1.birth_date
                ? new Date(ambassador1.birth_date).toISOString().split('T')[0]
                : 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('gender')} mt={2}>
            <Typography variant='body2'>
              Gender: {ambassador1.gender || 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('nationality')} mt={2}>
            <Typography variant='body2'>
              Nationality: {ambassador1.nationality?.join(', ') || 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('skin_tone')} mt={2}>
            <Typography variant='body2'>
              Skin Tone: {ambassador1.skin_tone || 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('profession')} mt={2}>
            <Typography variant='body2'>
              Profession: {ambassador1.profession?.join(', ') || 'Unknown'}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant='subtitle1' sx={{ mb: 2 }}>
            Ambassador 2
          </Typography>

          <Box sx={getDiffStyle('name')}>
            <Typography variant='body2'>Name: {ambassador2.name}</Typography>
          </Box>

          <Box sx={getDiffStyle('image_url')} mt={2}>
            <Button
              sx={{ alignContent: 'center' }}
              onClick={() => handleImageSelect('ambassador2')}
            >
              <Box
                component='img'
                src={ambassador2.image_url}
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  mt: 1,
                  border:
                    selectedImageSource === 'ambassador2'
                      ? '2px solid green'
                      : 'none',
                }}
              />
            </Button>
          </Box>

          <Box sx={getDiffStyle('birth_date')} mt={2}>
            <Typography variant='body2'>
              Birth Date:{' '}
              {ambassador2.birth_date
                ? new Date(ambassador2.birth_date).toISOString().split('T')[0]
                : 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('gender')} mt={2}>
            <Typography variant='body2'>
              Gender: {ambassador2.gender || 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('nationality')} mt={2}>
            <Typography variant='body2'>
              Nationality: {ambassador2.nationality?.join(', ') || 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('skin_tone')} mt={2}>
            <Typography variant='body2'>
              Skin Tone: {ambassador2.skin_tone || 'Unknown'}
            </Typography>
          </Box>

          <Box sx={getDiffStyle('profession')} mt={2}>
            <Typography variant='body2'>
              Profession: {ambassador2.profession?.join(', ') || 'Unknown'}
            </Typography>
          </Box>
        </Box>

        {/* Merged Data Column */}
        <Box>
          <Typography variant='subtitle1' sx={{ mb: 2 }}>
            Merged Result
          </Typography>

          <TextField
            fullWidth
            label='Name'
            value={mergedAmbassador.name}
            onChange={e => handleFieldChange('name', e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            mb={2}
          >
            <Box
              component='img'
              src={mergedAmbassador.image_url}
              sx={{
                width: 80,
                height: 80,
                borderRadius: '50%',
              }}
            />
          </Box>

          <TextField
            fullWidth
            type='date'
            label='Birth Date'
            value={
              mergedAmbassador.birth_date
                ? new Date(mergedAmbassador.birth_date)
                    .toISOString()
                    .split('T')[0]
                : ''
            }
            onChange={e => handleChangeDate(e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            select
            fullWidth
            label='Gender'
            value={mergedAmbassador.gender || 'Unknown'}
            onChange={e => handleFieldChange('gender', e.target.value)}
            sx={{ mb: 2 }}
          >
            <MenuItem value='Unknown'>Unknown</MenuItem>
            <MenuItem value='Female'>Female</MenuItem>
            <MenuItem value='Male'>Male</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
          </TextField>

          <Autocomplete
            multiple
            options={countryOptions}
            getOptionLabel={option => option.label}
            value={
              mergedAmbassador.nationality?.map(code => ({
                code,
                label: countries[code]?.label || code,
              })) || []
            }
            onChange={(_, newValue) => {
              handleFieldChange(
                'nationality',
                newValue.map(v => v.code),
              );
            }}
            renderInput={params => (
              <TextField {...params} label='Nationalities' sx={{ mb: 2 }} />
            )}
          />

          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={mergedAmbassador.profession || []}
            onChange={(_, newValue) => {
              handleFieldChange('profession', newValue);
            }}
            renderInput={params => (
              <TextField {...params} label='Profession' sx={{ mb: 2 }} />
            )}
          />
        </Box>
      </Box>

      <ApiErrorComponent error={apiError} />

      <Divider sx={{ my: 3 }} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant='outlined' onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => onMerge(mergedAmbassador, ambassador2.celebrity_id)}
          disabled={loading}
          sx={{
            backgroundColor: '#7F56D9',
            '&:hover': { backgroundColor: '#6941C6' },
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Merge Ambassadors'}
        </Button>
      </Box>
    </Box>
  );
};

export default AmbassadorMerge;
